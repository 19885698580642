
@import '../styles.scss';

.speed_test-calibration {
    @include button(48vw);
    height: 10vw;
      
}

  

