#micDot:focus {outline:0;}

.micDotOn {
  position: fixed;
  top: 1vw;
  left: 96vw;
  background-color: #00B64A;
  border: none;
  width: 3vw;
  height: 3vw;
  border-radius: 50vw;
}


.micDotOff {
  position: fixed;
  top: 1vw;
  left: 96vw;
  background-color: #B4443E;
  border: none;
  width: 3vw;
  height: 3vw;
  border-radius: 50vw;
}
