.phSVG-1 {
  fill: #231f20;
}

.phSVG-1, .phSVG-2, .phSVG-3, .phSVG-4, .phSVG-5, .phSVG-6, .phSVG-7, .phSVG-8 {
  fillRule: evenodd;
}

.phSVG-2 {
  fill: url(#linear-gradient);
}

.phSVG-3 {
  fill: #898489;
}

.phSVG-4 {
  fill: #bebebe;
}

.phSVG-5 {
  fill: #dadada;
}

.phSVG-6 {
  fill: #c9cbe2;
}

.phSVG-7 {
  fill: #313354;
}