@import '../styles.scss';

.cb-microphone-calibration {
  @include checkbox(21vw);
  height: 3vw;
}
  .cb-speakers-calibration {
    @include checkbox(35vw);
    height: 3vw;
  }
  .cb-speed_test-calibration {
    @include checkbox(49vw);
    height: 3vw;
    }

  .checkbox__container {
      position: absolute;
      top: 8vw;
      left: 28vw;
      width: 18vw;
    }