@import '../styles.scss';

.btn-draw-again {
  background-color: $medium-blue !important;
  font-size: 2.5vw !important;
  color: white !important;
  padding: 1% !important;
  margin-top: 1vw;
  outline: none !important;
  opacity: 1;
  width: 21vw !important;
}

.btn-draw-again__startup--animation {
  @include fade-in--transform(1s, 1.5s, 0%, 200%);
}

.btn-draw-again__clicked--animation {
  @include fade-in--fade-out(4s, 0s, 0%, 50%);
}

.current-ball__container {
  position: absolute;
  top: 8vw;
  left: 28vw;
  width: 18vw;
}

.current-ball__div {
  @include fade-in--transform(1s, 1s, -200%, 0%);
  animation-fill-mode: forwards;
  transform: translateY(-200%);

  align-items: center;
  background-color: $medium-dark-blue;
  border: solid;
  border-color: $dark-dark-blue;
  border-radius: 15px;
  border-width: .5vw;
  display: flex;
  flex-direction: column;
  margin: auto;
  opacity: 1;
  padding: 1vw 1vw 0 1vw;
  width: 100%;
}

.current-ball__ball-number {
  font-size: 4.5vw;
  margin: 0 0 0 0;
}

.current-ball__ball-letter {
  font-size: 1.5vw;
  margin: -.25vw 0 -.25vw 0;
}

.current-ball__label {
  color: $dark-dark-blue;
  font-size: 3vw;
  font-weight: bold;
  margin: 0;
  padding-bottom: 1vw;
}

.current-ball__ball {
  @include ball(10vw);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 10px 18px #333;
  margin-bottom: 2vw;
  overflow: hidden;
}

.current-ball__ball-inner1 {
  @include ball(8.5vw);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: white;
}

.current-ball__ball-inner2 {
  @include ball(7.75vw);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.current-ball__ball-inner3 {
  @include ball(7vw);
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
}

.current-ball__ball--empty {
  @include ball(10vw);

  background-color: darken($medium-dark-blue, 6);
  margin-bottom: 2vw;
}