@mixin fade-in--transform($duration, $delay, $translateX, $translateY) {

  // make animation unique
  $animation-name: unique-id() !global;
  
  animation: $duration #{$animation-name} $delay ease 1;
  animation-fill-mode: forwards;
  transform: translate($translateX, $translateY);
  opacity: 0;

  @keyframes #{$animation-name} {
    0% {
      transform: translate($translateX, $translateY);
      opacity: 0;
    }
  
    50% {
      opacity: 0;
    }
  
    100% {
      transform: translateX(0%);
      transform: translateY(0%);
      opacity: 1;
    }
  }
}

@mixin fade-in--fade-out($duration, $delay, $translateX, $translateY) {

  // make animation unique
  $animation-name: unique-id() !global;
  animation: $duration #{$animation-name} $delay ease 1;

  @keyframes #{$animation-name} {
    0% {
      transform: translateY(0%);
      opacity: 1;
    }
  
    10% {
      transform: translate($translateX, $translateY);
      opacity: 0;
    }
  
    90% {
      transform: translate($translateX - ($translateX * 2), $translateY - ($translateY * 2));
      opacity: 0;
    }
  
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
}
