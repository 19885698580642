.phSVG-1 {
  fill: #231f20;
}

.phSVG-1, .phSVG-2, .phSVG-3, .phSVG-4, .phSVG-5, .phSVG-6, .phSVG-7, .phSVG-8 {
  fillRule: evenodd;
}

.phSVG-2 {
  fill: url(#linear-gradient);
}

.phSVG-3 {
  fill: #898489;
}

.phSVG-4 {
  fill: #bebebe;
}

.phSVG-5 {
  fill: #dadada;
}

.phSVG-6 {
  fill: #c9cbe2;
}

.phSVG-7 {
  fill: #313354;
}
#micDot:focus {outline:0;}

.micDotOn {
  position: fixed;
  top: 1vw;
  left: 96vw;
  background-color: #00B64A;
  border: none;
  width: 3vw;
  height: 3vw;
  border-radius: 50vw;
}


.micDotOff {
  position: fixed;
  top: 1vw;
  left: 96vw;
  background-color: #B4443E;
  border: none;
  width: 3vw;
  height: 3vw;
  border-radius: 50vw;
}

.app-bingo__container {
  position: absolute;
  overflow: hidden;
  height: 100vh;
  width: 100vw; }

.recent-balls {
  -webkit-animation: 1s u45c63d69 0.5s ease 1;
          animation: 1s u45c63d69 0.5s ease 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transform: translate(0%, -200%);
          transform: translate(0%, -200%);
  opacity: 0;
  background-color: #8DA9C4;
  border: solid;
  border-color: #0B2545;
  border-radius: 15px;
  border-width: .5vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  left: 50vw;
  margin: 1vw 0 0 0;
  opacity: 0;
  top: 0vw;
  position: absolute;
  width: 45vw; }

@-webkit-keyframes u45c63d69 {
  0% {
    -webkit-transform: translate(0%, -200%);
            transform: translate(0%, -200%);
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1; } }

@keyframes u45c63d69 {
  0% {
    -webkit-transform: translate(0%, -200%);
            transform: translate(0%, -200%);
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1; } }

.recent-balls__ball-number {
  font-size: 3vw;
  margin: 0 0 0 0; }

.recent-balls__ball-letter {
  font-size: 1.5vw;
  margin: -.5vw 0 -.8vw 0; }

.recent-balls__label {
  color: #0B2545;
  font-size: 3vw;
  font-weight: bold;
  margin: 0;
  padding-bottom: 1vw; }

.recent-balls__ball {
  width: 7vw;
  height: 7vw;
  margin: 1vw;
  text-align: center;
  font-size: 2vw;
  line-height: 3vw;
  font-weight: 700;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 2px 10px 18px #333;
  overflow: hidden; }

.recent-balls__ball-inner1 {
  width: 6vw;
  height: 6vw;
  margin: 1vw;
  text-align: center;
  font-size: 2vw;
  line-height: 3vw;
  font-weight: 700;
  border-radius: 50%;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
  background-color: white; }

.recent-balls__ball-inner2 {
  width: 5.5vw;
  height: 5.5vw;
  margin: 1vw;
  text-align: center;
  font-size: 2vw;
  line-height: 3vw;
  font-weight: 700;
  border-radius: 50%;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden; }

.recent-balls__ball-inner3 {
  width: 5vw;
  height: 5vw;
  margin: 1vw;
  text-align: center;
  font-size: 2vw;
  line-height: 3vw;
  font-weight: 700;
  border-radius: 50%;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  background-color: white; }

/* ball placeholder for empty slot in recent-balls */
.recent-balls__empty-spot {
  background-color: #799aba;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 7vw;
  height: 7vw;
  margin: 1vw;
  border-radius: 50%; }

.app-bingo__container {
  position: absolute;
  overflow: hidden;
  height: 100vh;
  width: 100vw; }

.btn-draw-again {
  background-color: #0098C6 !important;
  font-size: 2.5vw !important;
  color: white !important;
  padding: 1% !important;
  margin-top: 1vw;
  outline: none !important;
  opacity: 1;
  width: 21vw !important; }

.btn-draw-again__startup--animation {
  -webkit-animation: 1s u8d9efe5f 1.5s ease 1;
          animation: 1s u8d9efe5f 1.5s ease 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transform: translate(0%, 200%);
          transform: translate(0%, 200%);
  opacity: 0; }

@-webkit-keyframes u8d9efe5f {
  0% {
    -webkit-transform: translate(0%, 200%);
            transform: translate(0%, 200%);
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1; } }

@keyframes u8d9efe5f {
  0% {
    -webkit-transform: translate(0%, 200%);
            transform: translate(0%, 200%);
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1; } }

.btn-draw-again__clicked--animation {
  -webkit-animation: 4s uf5972eca 0s ease 1;
          animation: 4s uf5972eca 0s ease 1; }

@-webkit-keyframes uf5972eca {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1; }
  10% {
    -webkit-transform: translate(0%, 50%);
            transform: translate(0%, 50%);
    opacity: 0; }
  90% {
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1; } }

@keyframes uf5972eca {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1; }
  10% {
    -webkit-transform: translate(0%, 50%);
            transform: translate(0%, 50%);
    opacity: 0; }
  90% {
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1; } }

.current-ball__container {
  position: absolute;
  top: 8vw;
  left: 28vw;
  width: 18vw; }

.current-ball__div {
  -webkit-animation: 1s u302a682b 1s ease 1;
          animation: 1s u302a682b 1s ease 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transform: translate(-200%, 0%);
          transform: translate(-200%, 0%);
  opacity: 0;
  animation-fill-mode: forwards;
  -webkit-transform: translateY(-200%);
          transform: translateY(-200%);
  -webkit-align-items: center;
          align-items: center;
  background-color: #8DA9C4;
  border: solid;
  border-color: #0B2545;
  border-radius: 15px;
  border-width: .5vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: auto;
  opacity: 1;
  padding: 1vw 1vw 0 1vw;
  width: 100%; }

@-webkit-keyframes u302a682b {
  0% {
    -webkit-transform: translate(-200%, 0%);
            transform: translate(-200%, 0%);
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1; } }

@keyframes u302a682b {
  0% {
    -webkit-transform: translate(-200%, 0%);
            transform: translate(-200%, 0%);
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1; } }

.current-ball__ball-number {
  font-size: 4.5vw;
  margin: 0 0 0 0; }

.current-ball__ball-letter {
  font-size: 1.5vw;
  margin: -.25vw 0 -.25vw 0; }

.current-ball__label {
  color: #0B2545;
  font-size: 3vw;
  font-weight: bold;
  margin: 0;
  padding-bottom: 1vw; }

.current-ball__ball {
  width: 10vw;
  height: 10vw;
  margin: 1vw;
  text-align: center;
  font-size: 2vw;
  line-height: 3vw;
  font-weight: 700;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 2px 10px 18px #333;
  margin-bottom: 2vw;
  overflow: hidden; }

.current-ball__ball-inner1 {
  width: 8.5vw;
  height: 8.5vw;
  margin: 1vw;
  text-align: center;
  font-size: 2vw;
  line-height: 3vw;
  font-weight: 700;
  border-radius: 50%;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
  background-color: white; }

.current-ball__ball-inner2 {
  width: 7.75vw;
  height: 7.75vw;
  margin: 1vw;
  text-align: center;
  font-size: 2vw;
  line-height: 3vw;
  font-weight: 700;
  border-radius: 50%;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden; }

.current-ball__ball-inner3 {
  width: 7vw;
  height: 7vw;
  margin: 1vw;
  text-align: center;
  font-size: 2vw;
  line-height: 3vw;
  font-weight: 700;
  border-radius: 50%;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  background-color: white; }

.current-ball__ball--empty {
  width: 10vw;
  height: 10vw;
  margin: 1vw;
  text-align: center;
  font-size: 2vw;
  line-height: 3vw;
  font-weight: 700;
  border-radius: 50%;
  background-color: #799aba;
  margin-bottom: 2vw; }

.app-bingo__container {
  position: absolute;
  overflow: hidden;
  height: 100vh;
  width: 100vw; }

.bingo-boredo {
  -webkit-animation: 1s u387be16d 0s ease 1;
          animation: 1s u387be16d 0s ease 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transform: translate(200%, 0%);
          transform: translate(200%, 0%);
  opacity: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: absolute;
  left: 50vw;
  opacity: 0;
  top: 12vw;
  width: 46vw;
  z-index: 10000; }

@-webkit-keyframes u387be16d {
  0% {
    -webkit-transform: translate(200%, 0%);
            transform: translate(200%, 0%);
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1; } }

@keyframes u387be16d {
  0% {
    -webkit-transform: translate(200%, 0%);
            transform: translate(200%, 0%);
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1; } }

.bingo-table {
  width: 100%; }

.bingo-table__container {
  border-radius: 15px;
  left: 45vw;
  overflow: hidden;
  padding: 0 0 0 0;
  top: 20vh;
  width: 100%; }

.bingo-table__header {
  font-size: 4vw;
  padding: 1%;
  width: 20%; }

.bingo-table__header--1 {
  background-color: #E53935; }

.bingo-table__header--2 {
  background-color: #8E24AA; }

.bingo-table__header--3 {
  background-color: #2196F3; }

.bingo-table__header--4 {
  background-color: #4CAF50; }

.bingo-table__header--5 {
  background-color: #FF9800; }

.cell {
  background-color: white !important;
  border: 3px solid #eee;
  border-bottom: 0;
  font-weight: 700;
  font-size: 3vw;
  height: 6vw !important;
  padding: 0;
  width: 20% !important;
  line-height: 50px;
  text-align: center; }

.cell-label {
  margin: 0;
  padding: 0; }

.not-selected {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 100%; }

.selected {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0;
  overflow: visible;
  height: 50px; }

.token {
  -webkit-align-items: center;
          align-items: center;
  background-color: #9944BB;
  border-radius: 50%;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 5.5vw;
  width: 5.5vw; }

/* table header */
th {
  color: white !important;
  height: 4vw;
  width: 4vw; }

/* table cell */
td {
  height: 4vw !important;
  width: 4vw !important; }

table {
  table-layout: fixed; }

.app-bingo__container {
  position: absolute;
  overflow: hidden;
  height: 100vh;
  width: 100vw; }

div, input {
	--board-width: 42vw;
	--board-height: 63vh;
}

.sudoku-board {
	position: fixed;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	left: 50vw;
	top: 30vh;
	width: var(--board-width);
	height: var(--board-height);
	border: 0;
	z-index: 1000000000;
	background: black;
}

.sudoku-row {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	width: calc(var(--board-width) - 1);
	height: calc(var(--board-height) / 9);
	vertical-align: middle;
}

.sudoku-cell {
	display: block;
	position: relative;
	-webkit-align-content: center;
	        align-content: center;
	-webkit-justify-content: center;
	        justify-content: center;
  	font-family: "Open Sans", sans-serif;
	width: calc(var(--board-width) / 10.5);
	height: calc(var(--board-height) / 11.5);
	padding:auto;
	margin: 0.5vw 0.5vh;
	border: 0;
	font-size: 1.5vw;
	text-align: center;
	vertical-align: middle;
	border: 1px solid;
	background: white;
}

.instruction {
  font-Size: 1.5vw;
  color: #fff;
  font-Weight: bold;
  border-Radius: .75vw;
  padding: 1vw;
  border: 0vw;
  align-Items: center;
  text-Align: center;
  width: 20vw;
  background-image: -webkit-linear-gradient(top, #064077, #0A69C4);
  background-image: linear-gradient(to bottom, #064077, #0A69C4);
  margin: 0vw;
  position: absolute;
  top: 30vw;
  left: 45vw;
  height: 15vw;
  border: 0.35vw solid #00AC28;
  box-shadow: 0px 0px 15px #00AC28; }

.instruction {
  font-Size: 1.5vw;
  color: #fff;
  font-Weight: bold;
  border-Radius: .75vw;
  padding: 1vw;
  border: 0vw;
  align-Items: center;
  text-Align: center;
  width: 20vw;
  background-image: -webkit-linear-gradient(top, #064077, #0A69C4);
  background-image: linear-gradient(to bottom, #064077, #0A69C4);
  margin: 0vw;
  position: absolute;
  top: 30vw;
  left: 45vw;
  height: 15vw;
  border: 0.35vw solid #00AC28;
  box-shadow: 0px 0px 15px #00AC28; }

.microphone-calibration {
  position: fixed;
  top: 20vw;
  left: 70vw;
  width: 20vw;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 4em;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #064077, #0A69C4);
  background-image: linear-gradient(to bottom, #064077, #0A69C4);
  cursor: pointer;
  border-radius: 0.75vw;
  padding-bottom: 0.2em;
  border: 0.35vw solid #00AC28;
  box-shadow: 0px 0px 15px #00AC28;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 10vw; }

.instruction {
  font-Size: 1.5vw;
  color: #fff;
  font-Weight: bold;
  border-Radius: .75vw;
  padding: 1vw;
  border: 0vw;
  align-Items: center;
  text-Align: center;
  width: 20vw;
  background-image: -webkit-linear-gradient(top, #064077, #0A69C4);
  background-image: linear-gradient(to bottom, #064077, #0A69C4);
  margin: 0vw;
  position: absolute;
  top: 30vw;
  left: 45vw;
  height: 15vw;
  border: 0.35vw solid #00AC28;
  box-shadow: 0px 0px 15px #00AC28; }

.speakers-calibration {
  position: fixed;
  top: 34vw;
  left: 70vw;
  width: 20vw;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 4em;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #064077, #0A69C4);
  background-image: linear-gradient(to bottom, #064077, #0A69C4);
  cursor: pointer;
  border-radius: 0.75vw;
  padding-bottom: 0.2em;
  border: 0.35vw solid #00AC28;
  box-shadow: 0px 0px 15px #00AC28;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 10vw; }

.instruction {
  font-Size: 1.5vw;
  color: #fff;
  font-Weight: bold;
  border-Radius: .75vw;
  padding: 1vw;
  border: 0vw;
  align-Items: center;
  text-Align: center;
  width: 20vw;
  background-image: -webkit-linear-gradient(top, #064077, #0A69C4);
  background-image: linear-gradient(to bottom, #064077, #0A69C4);
  margin: 0vw;
  position: absolute;
  top: 30vw;
  left: 45vw;
  height: 15vw;
  border: 0.35vw solid #00AC28;
  box-shadow: 0px 0px 15px #00AC28; }

.speed_test-calibration {
  position: fixed;
  top: 48vw;
  left: 70vw;
  width: 20vw;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 4em;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #064077, #0A69C4);
  background-image: linear-gradient(to bottom, #064077, #0A69C4);
  cursor: pointer;
  border-radius: 0.75vw;
  padding-bottom: 0.2em;
  border: 0.35vw solid #00AC28;
  box-shadow: 0px 0px 15px #00AC28;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 10vw; }

.instruction {
  font-Size: 1.5vw;
  color: #fff;
  font-Weight: bold;
  border-Radius: .75vw;
  padding: 1vw;
  border: 0vw;
  align-Items: center;
  text-Align: center;
  width: 20vw;
  background-image: -webkit-linear-gradient(top, #064077, #0A69C4);
  background-image: linear-gradient(to bottom, #064077, #0A69C4);
  margin: 0vw;
  position: absolute;
  top: 30vw;
  left: 45vw;
  height: 15vw;
  border: 0.35vw solid #00AC28;
  box-shadow: 0px 0px 15px #00AC28; }

.cb-microphone-calibration {
  position: fixed;
  top: 21vw;
  left: 91vw;
  width: 3vw;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 3em;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #064077, #0A69C4);
  background-image: linear-gradient(to bottom, #064077, #0A69C4);
  cursor: pointer;
  border-radius: 0.75vw;
  padding-bottom: 0.2em;
  border: 0em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 3vw; }

.cb-speakers-calibration {
  position: fixed;
  top: 35vw;
  left: 91vw;
  width: 3vw;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 3em;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #064077, #0A69C4);
  background-image: linear-gradient(to bottom, #064077, #0A69C4);
  cursor: pointer;
  border-radius: 0.75vw;
  padding-bottom: 0.2em;
  border: 0em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 3vw; }

.cb-speed_test-calibration {
  position: fixed;
  top: 49vw;
  left: 91vw;
  width: 3vw;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 3em;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #064077, #0A69C4);
  background-image: linear-gradient(to bottom, #064077, #0A69C4);
  cursor: pointer;
  border-radius: 0.75vw;
  padding-bottom: 0.2em;
  border: 0em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 3vw; }

.checkbox__container {
  position: absolute;
  top: 8vw;
  left: 28vw;
  width: 18vw; }


#vitalCont {

	position: fixed;
	top: 0%;
	right: 0%;
	width: 35vw;
	height: 30vw;

    background-image: -webkit-linear-gradient(top, #064077, #0A69C4);

    background-image: linear-gradient(to bottom, #064077, #0A69C4);
	padding: 2%;
	border-radius: 0% 0% 0% 5%;
  
    display: -webkit-flex;
  
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center;
	
	font-family: 'Open Sans', sans-serif;
	color: #fff;
  }

.vitalName {
	font-size: 5vw;
	text-align: center;
}

.vitalNumber{
	font-size: 8vw;
	margin-bottom: -10%;
	margin-top: -10%;
}
html {
    display: block;
    /*position: absolute;*/
}

body {
    margin: 0;
    display: -webkit-flex;
    display: flex;
    /*position: relative;*/
}

div {
    display: block;
}

#root {
    /*margin: 0;*/
}

.absolute {
    /*position: fixed;*/
    z-index: 500;
}

.react-board-cell {
    z-index: 500000;
    background-color: white;
}

.react-board-row {
    z-index: 500000;
}

.react-board {
    z-index: 500000;
}

/*.bgio-client { 
    z-index: 500000;
}*/

.checkers-board-cell {
    height: 2vh;
    width: 3vw;
}

/**
 *
 *
 *
 *
 *
 */

.App {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    text-align: center;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
}

.App-logo {
  /*animation: App-logo-spin infinite 2s linear;*/
  height: 100px;

}

.dev-buttons {
    -webkit-flex-direction: column;
            flex-direction: column;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 10px;
  color: white;
}

.App-header-hidden {
  display: none;
  visibility: hidden;
}

.hidden {
  display: none;
  visibility: hidden;
}

.App-title {
  font-size: 1.1em;
}

.App-intro {
  font-size: large;
}

.App-scene {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1000;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.sumerian-canvas-inner-container {
  position: relative;
  overflow: hidden;
}

.sumerian {
  position: absolute;
}

.dev-buttons {
    left: 10vw;
    top: 20vh;
    width: 15vw;
    height: 15vh;
    font-size: 16px;
   -webkit-justify-content: space-between;
           justify-content: space-between;
}

.vertical-container_development {
    position: absolute;
    top: 40vh;
    height: 40vh;
    max-height: 50vh;
    width: 10vw;
    left: 0.5vw;
    bottom: 9.5vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-content: space-between;
            align-content: space-between;
}

.vertical-item {
    /*flex: flex-shrink;*/
    font-size: 1em;
    width: 8vw;
    height: 10vh;
    margin-top: auto;
    margin-bottom: auto;
}

.Video-Container {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  text-align: center;
  height: 100vh;
  width: 100vw;
  -webkit-justify-content: center;
          justify-content: center;
}

.videoContainer_FullScreen {
    
}

video#splash-container {
  height: 100vh;
  width: auto;
  -webkit-justify-content: center;
          justify-content: center;
}

#splash-container {
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  margin: auto;
  z-index: 10000000000;
}

#summary-container {
    z-index: 100;
}

#summary-diagram-container {
    z-index: 100;
    position: absolute;
}

#toggle-summary {
    position: absolute;
    z-index: 100;
    /*display: inline-block;*/
    top: 94.5vh;
    left: 93.5vw;
}

.inline-button {
    position: absolute;
    display: inline-block;
    z-index: 100;
    left: 2vw;
}

#containerDiv {
    position: absolute;
    z-index: 200;
}

.react-board {
    width: 60vw;
    height: 60vh;
    top: 25vh;
    left: 30vw;
    z-index: 5000000;
}

.react-board-row {
    width: 50vw;
    height: 8vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    z-index: 5000000;
}

.react-board-cell {
    width: 6.75vw;
    height: 6.75vh;
    margin: 0;
    z-index: 5000000;
}

.whiteCell {
    background-color: white;
}

.blackCell {
    background-color: black;
}

/**
 *
 *
 * ************** diagram section **************
 *
 *
 */




.utterance-caption {
    font-size: .85em;
    padding-left: 6vw;
    margin-top: auto;
    margin-bottom: auto;
}

.summary-header-text {
    font-size: 5vh;
    padding-right: 5.5vw;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: auto;
    padding-bottom: auto;
}

.progress-bar {
    width: 25vw;
}

#summary-header-achievement {
    padding-right: 2.5vw;
    padding-top: auto;
    padding-bottom: auto;
    height: 100%;
}

#achievement-header {
    padding-top:0.6vh;
}

.appChart {
    background-color: #D2D6E8;
    left: 55vw;
    top: 25vh;
    /*width: 40vw;*/
    /*height: 30vh;*/
    width: 800px;
    height: 500px;
    position: absolute;
}

#achievement-captions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-align-content: flex-end;
            align-content: flex-end;
    padding-left: 19vw;
    padding-bottom: 0vh;
    padding-top: 0vh;
    margin-bottom: 1vh;
    margin-top: 0;
    font-size: 2vh;
    height: 1.5vh;
}

#hr-utterance {
    padding-left: 4.5vw;
}

#oxygen-utterance {
    padding-left: 4.2vw;
}

#bp-utterance {
    padding-left: 3vw;
}

#weight-utterance {
    padding-left: 6vw;
}

#temperature-utterance {
    padding-left: 4vw;
}

#achievement-score {
    margin-left: auto;
    padding-right: 4vw;
}



/* --------------- ELEMENT STYLING --------------- */
button {
    display: block;
}

.ui-button {
    position: absolute;
    z-index: 100;
}


.icon,
.text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center
}

.btnTemplate {
    /*position: absolute;*/
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #02645E;
    cursor: pointer;
    border-radius: .5vw;
    padding-bottom: .5vw;
    border: 0vw;
    height: 30vh;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 25vw;
}

.btnDone {
    /*position: absolute;*/
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #9944BB;
    cursor: pointer;
    border-radius: .5vw;
    padding-bottom: .5vw;
    border: 0vw;
    height: 30vh;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 20vw;
}

.btn-ecg {
    /*position: absolute;*/
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #02645E;
    cursor: pointer;
    border-radius: .5vw;
    padding-bottom: .5vw;
    border: 0vw;
    top: 20vh;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 15vw;
}

.btn-ecg.vertical-item {
    font-size: 1em;
    height: 4vh;
    width: 6vw;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    /*justify-content: center;*/
}

.btnNo {
    margin: 0.9em;
    font-family: "Open Sans", sans-serif;
    font-size: 6vw;
    color: #EEF0F6;
    background: #B4443E;
    cursor: pointer;
    border-radius: .4vw;
    padding-bottom: 0.1vw;
    border: 0vw;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 30vw;
}

#goto-vitals {
    font-size: 1em;
}

.vitalsTutorialImage {
    position: absolute;
    top: 0vw;
    bottom: 5vw;
    right: 10vw;
    width: 35vw;


/*    top: 20vw;
    bottom: 20vw;
    right: 10vw;
    width: 40vw;
    height: 60vh;*/
    margin: auto;
}

/* --------------- ON CLICK --------------- */

/* What happens on mouse-down */
.btnNo:active,

/* What happens once selected */
.btnNo:focus {
    -webkit-animation: borderPulseNo 1000ms infinite ease-out;
            animation: borderPulseNo 1000ms infinite ease-out;
    -webkit-animation: borderPulseNo 1s;
            animation: borderPulseNo 1s;
    background-color: #B4443E;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}


/* --------------- Animation --------------- */

/* Declare border pulse animation */
@-webkit-keyframes borderPulseNo {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(180, 68, 62, .4), 0em 0em 0em 0em rgba(180, 68, 62, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(180, 68, 62, .2), 0em 0em 0em .4em rgba(180, 68, 62, 0);
    }
}
@keyframes borderPulseNo {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(180, 68, 62, .4), 0em 0em 0em 0em rgba(180, 68, 62, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(180, 68, 62, .2), 0em 0em 0em .4em rgba(180, 68, 62, 0);
    }
}


/* Gradient properties */
button.btnNo {
    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}



/**
 *
 *
 *
 */


#gradient {
    width: 100vw;
    height: 10vh;
    top: 90vh;

    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));

    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

/**
 *
 *
 *
 *
 */



.st1 {
    fill: #d2d6e8;
}





/**
 *
 *
 *
 *
 *
 *
 *
 */



.btndone {
    font-family: "Open Sans", sans-serif;
    font-size: 5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #441155;
    cursor: pointer;
    border-radius: 1vw;
    padding-bottom: 1vw;
    border: 0vw;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 30vw;
}

/* --------------- ON CLICK --------------- */

/* What happens on mouse-down */
.btndone:active,

/* What happens once selected */
.btndone:focus {
    -webkit-animation: borderPulseDone 1000ms infinite ease-out;
            animation: borderPulseDone 1000ms infinite ease-out;
    -webkit-animation: borderPulseDone 1s;
            animation: borderPulseDone 1s;
    background-color: #BB84D0;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

.btndone:focus .white {
    fill: #ffffff;
}

.btndone:focus .purple {
    fill: #BB84D0
}


/* --------------- Animation --------------- */

/* Declare border pulse animation */
@-webkit-keyframes borderPulseDone {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(187, 132, 208, .4), 0em 0em 0em 0em rgb(187, 132, 208, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(187, 132, 208, .2), 0em 0em 0em .4em rgb(187, 132, 208, 0);
    }
}
@keyframes borderPulseDone {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(187, 132, 208, .4), 0em 0em 0em 0em rgb(187, 132, 208, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(187, 132, 208, .2), 0em 0em 0em .4em rgb(187, 132, 208, 0);
    }
}


/**
 *
 *
 *
 *
 *
 *
 *
 */



.btnhelp {
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #02645E;
    cursor: pointer;
    border-radius: .5vw;
    padding-bottom: .5vw;
    border: 0vw;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 15vw;
}

/* --------------- ON CLICK --------------- */

/* What happens on mouse-down */
.btnhelp:active,

/* What happens once selected */
.btnhelp:focus {
    -webkit-animation: borderPulseHelp 1000ms infinite ease-out;
            animation: borderPulseHelp 1000ms infinite ease-out;
    -webkit-animation: borderPulseHelp 1s;
            animation: borderPulseHelp 1s;
    background-color: #49C0AD;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

.btnhelp:focus .white {
    fill: #fff;
}

.btnhelp:focus .green {
    fill: #49C0AD;
}

/* Gradient properties */
button.btnhelp {
    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}


/**
 *
 *
 *
 *
 *
 *
 *
 */




.btnnotyet {
    font-family: "Open Sans", sans-serif;
    font-size: 5vw;
    font-weight: bold;
    color: #d2d6e8;
    background: #B4443E;
    cursor: pointer;
    border-radius: 1vw;
    padding-bottom: 1vw;
    padding-left: 1vw;
    border: 0vw;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 30vw;
}

/* --------------- ON CLICK --------------- */

/* What happens on mouse-down */
.btnnotyet:active,

/* What happens once selected */
.btnnotyet:focus {
    -webkit-animation: borderPulseNotYet 1000ms infinite ease-out;
            animation: borderPulseNotYet 1000ms infinite ease-out;
    -webkit-animation: borderPulseNotYet 1s;
            animation: borderPulseNotYet 1s;
    background-color: rgba(180, 68, 62, 0);
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

.btnnotyet:focus .st3 {
    fill: #fff;
}


/* Gradient properties */
button.btnnotyet {
    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}


/**
 *
 *
 *
 *
 *
 *
 *
 */



.btnyes {
    font-family: "Open Sans", sans-serif;
    font-size: 5vw;
    font-weight: bold;
    color: #d2d6e8;
    background: #00B64A;
    cursor: pointer;
    border-radius: 1vw;
    padding-bottom: 1vw;
    padding-left: 1vw;
    border: 0vw;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 30vw;
}

/* --------------- ON CLICK --------------- */

/* What happens on mouse-down */
.btnyes:active,

/* What happens once selected */
.btnyes:focus {
    -webkit-animation: borderPulseYes 1000ms infinite ease-out;
            animation: borderPulseYes 1000ms infinite ease-out;
    -webkit-animation: borderPulseYes 1s;
            animation: borderPulseYes 1s;
    background: rgba(0, 182, 74, 0);
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

.btnyes:focus .st0 {
    fill: rgba(0, 182, 74, 0);
    -webkit-transition: fill .5s ease-in;
    transition: fill .5s ease-in;
}


/* Gradient properties */
button.btnyes {
    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}


.container {
    max-width: 90%;
}

.progress-segment {
    display: -webkit-flex;
    display: flex;
    margin: 2vw 1vw 2vw 1vw;
}

.item {
    width: 100%;
    background-color: #D2D6E8;
    margin-right: 0.3vw;
    height: 3vw;
    border-radius: 0.3vw;
}

.item:first-child {
    border-top-left-radius: 1vw;
    border-bottom-left-radius: 0.3vw;
}

.item:last-child {
    border-top-right-radius: 0.3vw;
    border-bottom-right-radius: 1vw;
}

.item.segOne {
    background: #045c84 !important;
}

.item.segTwo {
    background: #13668e !important;
}

.item.segThree {
    background: #23769d !important;
}

.item.segFour {
    background: #328bb1 !important;
}

.item.segFive {
    background: #3fa1c7 !important;
}

.item.segSix {
    background: #4ab6dd !important;
}

.item.segSeven {
    background: #4BCBF3 !important;
}


/**
 *
 *
 *
 *
 *
 *
 *
 */



/*----- Rapid Response -----*/
.btnRR {
    margin-top: 5vh;
    margin-left: 5vw;
    margin-bottom: 1vw;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #009F93;
    cursor: pointer;
    border-radius: .5vw;
    padding-top: .75vw;
    padding-bottom: 1.1vw;
    border: 0vw;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 25vw;
}

.btnRR:active,
.btnRR:focus {
    -webkit-animation: borderPulseRR 1000ms infinite ease-out;
            animation: borderPulseRR 1000ms infinite ease-out;
    -webkit-animation: borderPulseRR 1s;
            animation: borderPulseRR 1s;
    background-color: #49C0AD;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

@-webkit-keyframes borderPulseRR {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}

@keyframes borderPulseRR {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}


/*----- Customer Support -----*/
.btnCS {
    margin-top: 4.75vh;
    margin-left: 5vw;
    margin-bottom: 1vw;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #009F93;
    cursor: pointer;
    border-radius: .5vw;
    padding-top: .75vw;
    padding-bottom: 1.1vw;
    border: 0vw;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 25vw;
}

.btnCS:active,
.btnCS:focus {
    -webkit-animation: borderPulseCS 1000ms infinite ease-out;
            animation: borderPulseCS 1000ms infinite ease-out;
    -webkit-animation: borderPulseCS 1s;
            animation: borderPulseCS 1s;
    background-color: #49C0AD;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

@-webkit-keyframes borderPulseCS {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}

@keyframes borderPulseCS {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}


/*----- Pocket MD -----*/
.btnPMD {
    margin-top: 4.75vh;
    margin-left: 5vw;
    margin-bottom: 1vw;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #009F93;
    cursor: pointer;
    border-radius: .5vw;
    padding-top: .75vw;
    padding-bottom: 1.1vw;
    border: 0vw;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 25vw;
}

.btnPMD:active,
.btnPMD:focus {
    -webkit-animation: borderPulsePMD 1000ms infinite ease-out;
            animation: borderPulsePMD 1000ms infinite ease-out;
    -webkit-animation: borderPulsePMD 1s;
            animation: borderPulsePMD 1s;
    background-color: #49C0AD;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

@-webkit-keyframes borderPulsePMD {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}

@keyframes borderPulsePMD {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}

/* Initiate Auto-Pulse animations */
button.pulse-button {
    -webkit-animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
            animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}

/* Gradient properties */
button.btndone {
    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}


/**
 *
 *
 *
 *
 *
 *
 *
 */


h1 {
    font-family: "Open Sans", sans-serif;
    font-size: 4vw;
    text-align: center;
    color: #EEF0F6;
    font-weight: normal;
}

.med-table {
    background: #DAE3EB;
    align-object: center;
    border-radius: 1vw;
    overflow: hidden;
}

tr:nth-child(odd) td {
    background: #D2D6E8;
}

table,
th {
    border: 0px;
    color: #EEF0F6;
    background: #045C84;
    text-align: center;
    font-size: 1.7vw;
    padding: 2vw 4vw 2vw 4vw;
    font-family: "Open Sans", sans-serif;
    table-border: 0; 
    border-collapse: collapse;
    cellspacing: 0;
}

table,
td {
    color: #000;
    text-align: center;
    font-size: 1.2vw;
    padding: 2vw;
    font-family: "Open Sans", sans-serif;
}


.bakground {

    padding-top: 15em;
    padding-bottom: 42em;
    padding-left: 18em;
    padding-right: 31em;
    margin-top: 1em;
    background-color: white;
}





/*************************/
/*      BTN_Addison      */
/*************************/

#btnaddison {
    background: #094074;
    top: 10vh; 
    left: 1vw;
    z-index: 10;
}

#btnSupport {
    background: #094074;
    top: 1.5vh; 
    left: 17vw;
    z-index: 10;    
}

#btnaddison:focus {
    -webkit-animation: borderPulseAddison 1000ms infinite ease-out;
            animation: borderPulseAddison 1000ms infinite ease-out;
    -webkit-animation: borderPulseAddison 1s;
            animation: borderPulseAddison 1s;
    background-color: #4BCBF3;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

#btnaddison:focus .a {
    fill: #4BCBF3;
}

#btnaddison:focus .background {
    fill: #fff;
}

@-webkit-keyframes borderPulseAddison {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(75, 203, 243, .4), 0em 0em 0em 0em rgb(75, 203, 243, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(75, 203, 243, .2), 0em 0em 0em .4em rgb(75, 203, 243, 0);
    }
}

@keyframes borderPulseAddison {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(75, 203, 243, .4), 0em 0em 0em 0em rgb(75, 203, 243, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(75, 203, 243, .2), 0em 0em 0em .4em rgb(75, 203, 243, 0);
    }
}

.btnaddison {
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    font-weight: bold;
    color: #d2d6e8;
    background: #094074;
    cursor: pointer;
    border-radius: .5vw;
    padding-bottom: .5vw;
    border: 0vw;
    width: 15vw;
}

/* --------------- ON CLICK --------------- */

/* What happens on mouse-down */
.btnaddison:active,

/* What happens once selected */
.btnaddison:focus {
    -webkit-animation: borderPulseAddison 1000ms infinite ease-out;
            animation: borderPulseAddison 1000ms infinite ease-out;
    -webkit-animation: borderPulseAddison 1s;
            animation: borderPulseAddison 1s;
    background-color: #4BCBF3;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

.btnaddison:focus .a {
    fill: #4BCBF3;
}

.btnaddison:focus .background {
    fill: #fff;
}


/*******************
* Summary Buttons *
*******************/

/* Oxygen */

#oxygen-levels-summary {
    background-color: #00B54A;
    color: #EEF0F6;

}

.st0 {
    fill: #FF001E;
}

.st1 {
    fill: #FFFFFF;
}

.st2 {
    fill: #FAFAFA;
}

.st3 {
    fill: #434343;
}

.st4 {
    fill: #8DA9C4;
}

.st5 {
    fill: #3A4456;
}

.st6 {
    fill: none;
    stroke: #8DA9C4;
    strokeWidth: 3;
    strokeLinecap: round;
    strokeMiterlimit: 10;
}

.st7 {
    fill: #43535C;
}

.st8 {
    opacity: 0.5;
}

.st9 {
    opacity: 0.5;
    enableBackground: new;
}

#hr-chart canvas {
    display: "block"
}

#oxygen-value {}

#blood-pressure-summary {
    color: #EEF0F6;
    background-color: #B51E2D;

}

#bp-value {}

#weight-summary {
    color: #EEF0F6;
    background-color: #00B54A;
    /*  background-color: rgba(238, 27, 35, .75); */

}

#temperature-summary {
    color: #EEF0F6;
    background-color: #00B54A;

}

.icon-svg {
    float: left;
    padding: 0% 1vw 0% 1vw;
    margin-top: auto;
    margin-bottom: auto;
}

.vital-name {
    margin-top: auto;
    margin-bottom: auto;
    padding-left: .5vw;
    font-size: 1.25em;
}

.vitals-text {
    padding-right: 5%;
}

.summary-back-button {
    display: -webkit-flex;
    display: flex;
    font-family: "Open Sans", sans-serif;
    font-size: 18pt;
    cursor: pointer;
    color: #EEF0F6;
    background-color: #00B54A;
    font-weight: bold;
    -webkit-justify-content: center;
            justify-content: center;
    background: rgba(11, 37, 69, .85);
    border: 0;

}

.summary-back-button:hover {
    background-color: green;
}

#hr-levels-summary {

    background-color: #00B54A;
    color: #EEF0F6;
}

.green-label {
    background-color: #00b64a;
    height: 30px;
    width: 60px;
    text-align: center;
}

.yellow-label {
    background-color: #ffbb33;
    height: 30px;
    width: 60px;
    text-align: center;
}

.red-label {
    background-color: #b4443e;
    height: 30px;
    width: 50px;
    text-align: center;
}

.label-text {
    margin-left: 1vw;
    font-size: 14pt;
    color: #EEF0F6;
    -webkit-align-content: center;
            align-content: center;
}

.two-item-row {
    width: 10vw;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.chart-legend {
    margin-top: 1vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    font-size: 16pt;
}


/*************************/
/*    Html_Meditation    */
/*************************/

#medVideo {
    position: fixed;
    height: 100vh;
    right: 0vw;
}


/*************************/
/*   BTN_IFeelBetter     */
/*************************/

#btnBetter {
    top: 32vw;
    background: #00B64A;
}

#btnBetter:focus {
    -webkit-animation: borderPulseYes 1000ms infinite ease-out;
            animation: borderPulseYes 1000ms infinite ease-out;
    -webkit-animation: borderPulseYes 1s;
            animation: borderPulseYes 1s;
    background: rgba(0, 182, 74, 1);
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

#btnBetter:focus .checkMark {
    fill: #fff;
}

@-webkit-keyframes borderPulseYes {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(0, 182, 74, .4), 0em 0em 0em 0em rgba(0, 182, 74, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(0, 182, 74, .2), 0em 0em 0em .4em rgba(0, 182, 74, 0);
    }
}

@keyframes borderPulseYes {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(0, 182, 74, .4), 0em 0em 0em 0em rgba(0, 182, 74, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(0, 182, 74, .2), 0em 0em 0em .4em rgba(0, 182, 74, 0);
    }
}

.btnSmallSierra {
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    font-weight: bold;
    color: #d2d6e8;
    cursor: pointer;
    border-radius: .5vw;
    padding-bottom: .5vw;
    border: 0vw;
 width: 12vw;
    position: fixed;
    /* right: 12.5vw; */
    right: 86.5vw;
    top: 80vh;

    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;

}

/*************************/
/*     BTN_Nutrition     */
/*************************/

.btnNutrition {
    top: 45.5vw;
    background:#009F93;
}

.btnNutrition:focus {
    -webkit-animation: borderPulseDone 1000ms infinite ease-out;
            animation: borderPulseDone 1000ms infinite ease-out;
    -webkit-animation: borderPulseDone 1s;
            animation: borderPulseDone 1s;
    background-color: #009F93;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

.btnNutrition:focus .white {
    fill: #ffffff;
}

.btnNutrition:focus .primaryGreen {
    fill: #009F93;
}

@-webkit-keyframes borderPulseNutrition {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(0, 159, 147, .4), 0em 0em 0em 0em rgba(0, 159, 147, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(0, 159, 147, .2), 0em 0em 0em .4em rgba(0, 159, 147, 0);
    }
}

@keyframes borderPulseNutrition {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(0, 159, 147, .4), 0em 0em 0em 0em rgba(0, 159, 147, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(0, 159, 147, .2), 0em 0em 0em .4em rgba(0, 159, 147, 0);
    }
}


/*************************/
/*   BTN_INEEDMOREHELP   */
/*************************/

#btnNeedHelp {
    top: 42.5vw;
    background: #B4443E;
    padding-left: 1.5vw;
}

#btnNeedHelp:focus {
    -webkit-animation: borderPulseNotYet 1000ms infinite ease-out;
            animation: borderPulseNotYet 1000ms infinite ease-out;
    -webkit-animation: borderPulseNotYet 1s;
            animation: borderPulseNotYet 1s;
    background-color: rgba(180, 68, 62, 0);
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #fff;
}

#btnNeedHelp:focus .notYetX {
    fill: #fff;
}

@-webkit-keyframes borderPulseNotYet {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(180, 68, 62, .4), 0em 0em 0em 0em rgb(180, 68, 62, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(180, 68, 62, .2), 0em 0em 0em .4em rgb(180, 68, 62, 0);
    }
}

@keyframes borderPulseNotYet {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(180, 68, 62, .4), 0em 0em 0em 0em rgb(180, 68, 62, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(180, 68, 62, .2), 0em 0em 0em .4em rgb(180, 68, 62, 0);
    }
}


/*************************/
/*      BTN_Stressed     */
/*************************/

#stressed {
    top: 32vw;
    background: #016E75;
}

#stressed:focus {
    -webkit-animation: borderPulseYes 1000ms infinite ease-out;
            animation: borderPulseYes 1000ms infinite ease-out;
    -webkit-animation: borderPulseYes 1s;
            animation: borderPulseYes 1s;
    background: rgba(1, 110, 117, 1);
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

#stressed:focus .checkMark {
    fill: #fff;
}

@keyframes borderPulseYes {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(0, 182, 74, .4), 0em 0em 0em 0em rgba(0, 182, 74, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(0, 182, 74, .2), 0em 0em 0em .4em rgba(0, 182, 74, 0);
    }
}


/*************************/
/*  Login Form           */
/*************************/
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="username"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.h3 {
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 1.2;
    color: inherit;
}

label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}

.signin-btn{
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    display: block;
    width: 100%;
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
}



































































































/*************************/
/*    Generic Buttons    */
/*************************/

.btnSmall {
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    font-weight: bold;
    color: #d2d6e8;
    cursor: pointer;
    border-radius: .5vw;
    padding-bottom: .5vw;
    border: 0vw;
    width: 15vw;
    position: absolute;
    z-index: 5;

    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}

.btnMedium {
    margin-top: 5vh;
    margin-left: 5vw;
    margin-bottom: 1vw;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #009F93;
    cursor: pointer;
    border-radius: .5vw;
    padding-top: .75vw;
    padding-bottom: 1.1vw;
    border: 0vw;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 25vw;
    z-index: 5;

    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}

.btnMedium:focus {
    -webkit-animation: borderPulseMedium 1000ms infinite ease-out;
            animation: borderPulseMedium 1000ms infinite ease-out;
    -webkit-animation: borderPulseMedium 1s;
            animation: borderPulseMedium 1s;
    background-color: #49C0AD;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

@-webkit-keyframes borderPulseMedium {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}

@keyframes borderPulseMedium {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}

@-webkit-keyframes borderPulseHelp {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}

@keyframes borderPulseHelp {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}



.btnLarge {
    font-family: "Open Sans", sans-serif;
    font-size: 5vw;
    color: #d2d6e8;
    font-weight: bold;
    cursor: pointer;
    border-radius: 1vw;
    padding-bottom: 1vw;
    border: 0vw;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 30vw;
    background: b00b00;
    position: fixed;
    right: 12.5vw;
    z-index: 5;

    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}


.btnTall {
    margin-top: 5vw;
    font-family: "Open Sans", sans-serif;
    font-size: 2vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #0098C6;
    cursor: pointer;
    border-radius: .5vw;
    padding-top: .75vw;
    padding-bottom: 1.1vw;
    border: 0vw;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 13vw;
    height: 8vw;
    z-index: 5;
    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}

.btnTall:focus {
    -webkit-animation: borderPulseTall 1000ms infinite ease-out;
            animation: borderPulseTall 1000ms infinite ease-out;
    -webkit-animation: borderPulseTall 1s;
            animation: borderPulseTall 1s;
    background-color: #4BCBF3;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

@-webkit-keyframes borderPulseTall {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(75, 203, 243, .4), 0em 0em 0em 0em rgb(75, 203, 243, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(75, 203, 243, .2), 0em 0em 0em .4em rgb(75, 203, 243, 0);
    }
}

@keyframes borderPulseTall {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(75, 203, 243, .4), 0em 0em 0em 0em rgb(75, 203, 243, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(75, 203, 243, .2), 0em 0em 0em .4em rgb(75, 203, 243, 0);
    }
}



.left-button {
    left: 1vw;
}


/*************************/
/*        BTN_Done       */
/*************************/

#btndone {
    top: 42.5vw;
    background: #441155;
    left: 57vw;
    position: absolute;
}

#btndone:focus {
    -webkit-animation: borderPulseDone 1000ms infinite ease-out;
            animation: borderPulseDone 1000ms infinite ease-out;
    -webkit-animation: borderPulseDone 1s;
            animation: borderPulseDone 1s;
    background-color: #BB84D0;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

#btndone:focus .white {
    fill: #ffffff;
}

#btndone:focus .purple {
    fill: #BB84D0
}

@keyframes borderPulseDone {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(187, 132, 208, .4), 0em 0em 0em 0em rgb(187, 132, 208, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(187, 132, 208, .2), 0em 0em 0em .4em rgb(187, 132, 208, 0);
    }
}


/*************************/
/*        BTN_Ready      */
/*************************/

#btnready {
    top: 42.5vw;
    background: #441155;
}

#btnready:focus {
    -webkit-animation: borderPulseDone 1000ms infinite ease-out;
            animation: borderPulseDone 1000ms infinite ease-out;
    -webkit-animation: borderPulseDone 1s;
            animation: borderPulseDone 1s;
    background-color: #BB84D0;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

#btnready:focus .white {
    fill: #ffffff;
}

#btnready:focus .purple {
    fill: #BB84D0
}

@keyframes borderPulseDone {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(187, 132, 208, .4), 0em 0em 0em 0em rgb(187, 132, 208, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(187, 132, 208, .2), 0em 0em 0em .4em rgb(187, 132, 208, 0);
    }
}


/*************************/
/*        BTN_Help       */
/*************************/

#btnhelp {
    background: #02645E;
    top: 1.5vh;
}

#btnhelp:focus {
    -webkit-animation: borderPulseHelp 1000ms infinite ease-out;
            animation: borderPulseHelp 1000ms infinite ease-out;
    -webkit-animation: borderPulseHelp 1s;
            animation: borderPulseHelp 1s;
    background-color: #49C0AD;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

#btnhelp:focus .white {
    fill: #fff;
}

#btnhelp:focus .green {
    fill: #49C0AD;
}

@keyframes borderPulseHelp {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(73, 192, 173, .4), 0em 0em 0em 0em rgba(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(73, 192, 173, .2), 0em 0em 0em .4em rgba(73, 192, 173, 0);
    }
}


/*************************/
/*       BTN_NotYet      */
/*************************/

#btnnotyet {
    bottom: 10vh;
    background: #B4443E;
    padding-left: 1.5vw;
    position: absolute;
    right: 16vw;
}

#btnnotyet:focus {
    -webkit-animation: borderPulseNotYet 1000ms infinite ease-out;
            animation: borderPulseNotYet 1000ms infinite ease-out;
    -webkit-animation: borderPulseNotYet 1s;
            animation: borderPulseNotYet 1s;
    background-color: rgba(180, 68, 62, 0);
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #fff;
}

#btnnotyet:focus .st3 {
    fill: #fff;
}

@keyframes borderPulseNotYet {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(180, 68, 62, .4), 0em 0em 0em 0em rgb(180, 68, 62, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(180, 68, 62, .2), 0em 0em 0em .4em rgb(180, 68, 62, 0);
    }
}


/*************************/
/*         BTN_No        */
/*************************/

#btnno {
    top: 42.5vw;
    background: #B4443E;
    padding-left: 1.5vw;
}

#btnno:focus {
    -webkit-animation: borderPulseNo 1000ms infinite ease-out;
            animation: borderPulseNo 1000ms infinite ease-out;
    -webkit-animation: borderPulseNo 1s;
            animation: borderPulseNo 1s;
    background-color: rgba(180, 68, 62, 0);
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #fff;
}

#btnno:focus .st3 {
    fill: #fff;
}

@keyframes borderPulseNo {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(180, 68, 62, .4), 0em 0em 0em 0em rgb(180, 68, 62, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(180, 68, 62, .2), 0em 0em 0em .4em rgb(180, 68, 62, 0);
    }
}



/*************************/
/*        BTN_Yes        */
/*************************/

#btnyes {
    bottom: 25vh;
    background: #00B64A;
    padding-left: 1.5vw;
    position: absolute;
    right: 16vw;
}

#btnyes:focus {
    -webkit-animation: borderPulseYes 1000ms infinite ease-out;
            animation: borderPulseYes 1000ms infinite ease-out;
    -webkit-animation: borderPulseYes 1s;
            animation: borderPulseYes 1s;
    background: rgba(0, 182, 74, 1);
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

#btnyes:focus .checkMark {
    fill: #fff;
}

@keyframes borderPulseYes {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(0, 182, 74, .4), 0em 0em 0em 0em rgba(0, 182, 74, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(0, 182, 74, .2), 0em 0em 0em .4em rgba(0, 182, 74, 0);
    }
}


/*************************/
/*    ReadyAddisonButton   */
/*************************/

#readyaddison {
    top: 16vw;
    background: #00B64A;
}

#readyaddison:focus {
    -webkit-animation: borderPulseYes 1000ms infinite ease-out;
            animation: borderPulseYes 1000ms infinite ease-out;
    -webkit-animation: borderPulseYes 1s;
            animation: borderPulseYes 1s;
    background: rgba(0, 182, 74, 1);
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

#readyaddison:focus .checkMark {
    fill: #fff;
}

@keyframes borderPulseYes {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(0, 182, 74, .4), 0em 0em 0em 0em rgba(0, 182, 74, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(0, 182, 74, .2), 0em 0em 0em .4em rgba(0, 182, 74, 0);
    }
}


/*************************/
/*       AlertTab      */
/*************************/

.alertBackground {
    position: fixed;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    width: 35vw;
    height: 60vh;
    border-radius: .75vw;
    background-color: rgba(58, 68, 86, 1);
}

.alertTitle {
    padding: 2vw 0vw 0vw 0vw;
    background-color: #ffbb33;
    border-radius: .75vw .75vw 0vw 0vw;
    height: 13vh;
    font-family: "Roboto Condensed";
    font-size: 4vw;
    font-weight: bold;
    text-align: center;
    color: #3A4456;
}

.alertText {
    padding: 1vw;
    font-family: "Roboto Condensed";
    font-size: 2.5vw;
    text-align: center;
    color: #DAE3EB;
}

#proHealthSVG {
    position: flex;
    margin-top: 0.5vw;
}


/*************************/
/*        Captions       */
/*************************/

#captionGradient {
    position: absolute;
    width: 90vw;
    height: 10vh;
    top: 90vh;
    background: -webkit-radial-gradient(bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    z-index: 1000; /*jkeys addition 1/1/19*/
}

#captions {
    width: 43vw;
    height: 10vh;
    margin-left: 2vw;
    line-height: 3.5vh;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 2.9vh;
    font-weight: 300;
    text-align: left;
    position: fixed;
    top: 87vh;
}

.captions {
    width: 43vw;
    height: 10vh;
    margin-left: 2vw;
    line-height: 3.5vh;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 2.9vh;
    font-weight: 300;
    text-align: left;
    position: fixed;
    top: 90vh;
}


/*************************/
/*     GreatJobTab     */
/*************************/

.TakenScreen {
    position: fixed;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    width: 35vw;
    height: 60vh;
    border-radius: .75vw;
    background-color: #3A4456;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 2vw;
    color: #DAE3EB;
}

.GreatJob {
    background-color: #009F93;
    border-radius: .75vw .75vw 0vw 0vw;
    height: 14vh;
    width: 35vw;
    font-family: "Roboto Condensed";
    font-size: 4vw;
    text-align: center;
    display: inline-block;
    padding-top: 0.6vw;
}

.Streak {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 8vw;
    color: #DAE3EB;
    padding: 0vw;
}


.progress-segment {
    display: -webkit-flex;
    display: flex;
    margin: 2vw 1vw 2vw 1vw;
}

.item {
    width: 100%;
    background-color: #D2D6E8;
    margin-right: 0.3vw;
    height: 3vw;
    border-radius: 0.3vw;
}

.item:first-child {
    border-top-left-radius: 0.75vw;
    border-bottom-left-radius: 0.75vw;
}

.item:last-child {
    border-top-right-radius: 0.75vw;
    border-bottom-right-radius: 0.75vw;
}

.item.segOne {
    background: #4BCBF3 !important;
}

.item.segTwo {
    background: #53cdf4 !important;
}

.item.segThree {
    background: #5fd1f4 !important;
}

.item.segFour {
    background: #6cd4f5 !important;
}

.item.segFive {
    background: #79d7f6 !important;
}

.item.segSix {
    background: #85daf6 !important;
}

.item.segSeven {
    background: #8CDDF7 !important;
}


/*************************/
/*        Help_Tab       */
/*************************/

.helpTab {
    position: fixed;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    width: 35vw;
    height: 45vh;
    border-radius: .75vw;
    margin-left: 45vw;
    background-color: rgba(58, 68, 86, 1);
}

.btnrefreshpage {
    float: left;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #B4443E;
    cursor: pointer;
    border-radius: .5vw 0vw .5vw 0vw;
    padding-bottom: .5vw;
    border: 0vw;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 12vw;
}

.btnclosehelp {
    float: right;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #B4443E;
    cursor: pointer;
    border-radius: 0vw .75vw 0vw .5vw;
    padding-bottom: .5vw;
    border: 0vw;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 10.5vw;
}

#btnRR:focus,
#btnCS:focus,
#btnPMD:focus {
    -webkit-animation: borderPulseHelp 1000ms infinite ease-out;
            animation: borderPulseHelp 1000ms infinite ease-out;
    -webkit-animation: borderPulseHelp 1s;
            animation: borderPulseHelp 1s;
    background-color: #49C0AD;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

@keyframes borderPulseHelp {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}

@keyframes borderPulseHelp {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
    }
}


/*************************/
/* MedicationChartTab */
/*************************/

.med-table {
    position: fixed;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    border-radius: 1vw;
    overflow: hidden;
    font-family: "Open Sans", sans-serif;
    text-align: center;
}

.med-table,
th {
    border: 0px;
    color: #EEF0F6;
    background: #045C84;
    font-size: 1.7vw;
    padding: 2vw 4vw 2vw 4vw;
    table-border: 0;
    border-collapse: collapse;
    cellspacing: 0;
}

.med-table,
tr,
td {
    background: #DAE3EB;
    color: #000;
    font-size: 1.2vw;
    padding: 2vw;
}

.med-table,
tr:nth-child(odd) td {
    background: #D2D6E8;
}


/*************************/
/*      NextMedTab     */
/*************************/

.NextBackground {
    position: fixed;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    width: 35vw;
    height: 60vh;
    border-radius: .75vw;
    background-color: #3A4456;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 3vw;
    color: #DAE3EB;
}

.NextTitle {
    padding: 0.6vw 0vw 0.6vw 0vw;
    background-color: #8DA9C4;
    border-radius: .75vw .75vw 0vw 0vw;
    height: 13vh;
    font-family: "Roboto Condensed";
    font-size: 3vw;
    text-align: center;
}

.YourNextMed {
    padding: 2vw;
    font-size: 2.2vw;
}

.NextMedDate,
.NextMedTime {
    padding: 2vw;
    font-size: 3vw;
}


/*************************/
/*   SnoozeTimer   */
/*************************/

.timerBox {
    position: fixed;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    display: -webkit-flex;
    display: flex;
    width: 30vw;
    height: 30vw;
    background: #3A4456;
    border-radius: .7vw;
}

.c-container {
    position: relative;
    width: 100%;
    padding: 5vw;
    margin: auto;
}

.c-container .c-text {
    position: absolute;
    width: 100%;
    text-align: center;
    color: #DAE3EB;
    font-size: 2vw;
    font-family: "Open Sans", sans-serif;
    line-height: 2vw;
    top: 50%;
    margin-left: -5vw;
    margin-top: 3vw;
}

.c-container svg {
    width: 100%;
}


/*************************/
/*   BPDiagram_Summary   */
/*************************/

#bp-diagram-container {
    height: 45vh;
}


/*************************/
/*   HRDiagram_Summary   */
/*************************/

#heart-rate-summary {
    display: -webkit-flex;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    height: 8vh;
    background-color: #00B54A;
    color: #EEF0F6;
    /*   background-color: #00b64a; */

}

#hr-diagram-container {
    height: 45vh;
}


/*************************/
/* OxygenDiagram_Summary */
/*************************/

#oxygen-diagram-container {
    display: block;
    height: 45vh;
}


/*************************/
/*  ScaleDiagram_Summary */
/*************************/

#scale-diagram-container {
    height: 45vh;
}


/*************************/
/*    Summary_Diagram    */
/*************************/

html {
    display: block;
}

div {
    display: block;
}

.addison-captions {
    display: block;
    text-align: center;
}

.diagram {
    position: fixed;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #D2D6E8;
    background: #3A4456;
    cursor: pointer;
    border-radius: .75vw;
    border: 0vw;
    text-align: center;
    width: 35vw;
    height: 80vh;
}

div.canvas {
    display: block;
    padding: 0;
    margin: 0;
    -webkit-flex: 1 1;
            flex: 1 1;
    height: 60vh;
}

.summary-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: -1.4vw;
    padding-top: 1vw;
    background: #758299;
    border-radius: 0.75vw 0.75vw 0 0;
    height: 7vw;
}

#summary-header-text {
    padding-right: 3.5vw;
    padding-top: 1.5vw;
}

#streakContainer {
    height: 0vw;
    font-size: 2vh;
}

#streakTitle {
    margin: -1.45vw 0vw 0vw 1.5vw;
}

#achievement-score {
    margin: auto;
    padding-top: 2.6vh;
    margin-right: 0vw;
}


#achievement-container {
    display: -webkit-flex;
    display: flex;
    width: 50%;
}


#summary-header-achievement {
    padding-right: 2.5vw;
    height: 100%;
}


#youCanSay {
    margin: 1vw;
    margin-left: 1.5vw;
    margin-top: 1.5vw;
    margin-bottom: 1.5vw;
    float: left;
    font-size: 1.25vw;
    font-weight: normal;
}

.utterances {
    display: none;
    font-size: 1.25vw;
    font-weight: normal;
    float: right;
}

#bar0 {
    background: #35aecd !important; 
}

#bar1 {
       background-color: #35aecd !important;  
}

#bar2 {
       background-color: #35aecd !important;  
}

#bar3 {
       background-color: #35aecd !important;   
}

#bar4 {
       background-color: #35aecd !important;  
}

#bar5 {
       background-color: #35aecd  !important; 
}

#bar6 {
    /*   background-color: #3FC0DD  !important; */
}

.achievement-button {
    display: -webkit-flex;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0.25vw;
    margin-left: 0.25vw;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    cursor: pointer;
    border-radius: 0.75vw;
    width: 3.25vw;
    height: 3vh;
    background-color: #D2D6E8;
}

.left-achievement {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.right-achievement {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.middle-achievement {
    border-radius: 0;
}

.summary-button {
    display: -webkit-flex;
    display: flex;
    font-family: "Open Sans", sans-serif;
    font-size: 1.5vw;
    cursor: pointer;
    color: #EEF0F6;
    background-color: #0098C6;
    width: 32vw;
    height: 8vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
    border: 0vw;
    border-radius: 0.75vw;
}

.summary-button:active {
    background-color: #758299;
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
}

#heart-summary {
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
}

.result {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 2vw;
}

#heart-rate-summary {
    margin-top: 2vw;
    background-color: #00B54A;
    color: #EEF0F6;
    /*   background-color: #00b64a; */

}

#oxygen-levels-summary {
    background-color: #00B54A;
    color: #EEF0F6;

}

#hr-chart canvas {
    display: "block"
}

#oxygen-value {}

#blood-pressure-summary {
    color: #EEF0F6;
    background-color: #B51E2D;

}

#bp-value {}

#weight-summary {
    color: #EEF0F6;
    background-color: #00B54A;
    /*  background-color: rgba(238, 27, 35, .75); */

}

#temperature-summary {
    color: #EEF0F6;
    background-color: #B51E2D;

}

.icon-svg {
    float: left;
    padding: 0vw 2vw 0vw 2vw;
    margin-top: auto;
    margin-bottom: auto;
}

.vital-name {
    font-size: 1.5vw;
    margin-top: auto;
    margin-bottom: 0.5vh;
    margin-left: 1vw;
}

.vitals-text {
    padding-right: 2vw;
}

.summary-back-button {
    font-family: "Open Sans", sans-serif;
    font-size: 1vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #0098C6;
    cursor: pointer;
    border-radius: 0vw 0.5vw 0vw 0.5vw;
    border: 0vw;
    width: 12vw;
    height: 3vw;
    margin-top: -1vw;
}

.summary-vitals-text {
    width: 17vw;
    padding-right: 9vw;
    padding-top: 1.5vw;
}


#hr-levels-summary {

    background-color: #B51E2D;
    color: #EEF0F6;
}

.chart-legend {
    margin-top: 2vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    margin-left: -4.9vw;

}

.green-label {
    background-color: #00b64a;
    height: 2vw;
    width: 4vw;
    border-radius: 0.35vw;

}

.yellow-label {
    background-color: #ffbb33;
    height: 2vw;
    width: 4vw;
    border-radius: 0.35vw;

}

.red-label {
    background-color: #b4443e;
    height: 2vw;
    width: 4vw;
    border-radius: 0.35vw;
    margin-left: 2.5vw;

}

.label-text {
    width: 1vw;
    margin-left: 4.5vw;
    margin-top: 0.25vw;
    font-size: 1vw;
    color: #EEF0F6;
    text-align: left;
}

.two-item-row {
    width: 10vw;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}


/*************************/
/*  TempDiagram_Summary  */
/*************************/

#temp-diagram-container {
    height: 45vh;
}


/*************************/
/*   BGDiagram_Summary   */
/*************************/

#bg-diagram-container {
    height: 45vh;
}


/*************************/
/*      VideoEntity      */
/*************************/
/*
video {
    width: 100vw;
    height: auto;
    display: block;
    object-fit: fill;
}
*/

.video-container {
    top: 25vh;
    /* shift the container down */
    bottom: 25vh;
    /* shift the container up */
    left: 50vw;
    /* shift the container to the right of the screen */
    right: 10vw;
    /* give the container a right-margin of 10% viewport width */
    width: auto;
    height: 50vh;
    /* auto height so the video is sized correctly */
    position: fixed;
    display: block;
}

/*************************/
/*  Html_Pendant_Pressed */
/*************************/

.pendantTab {
    position: fixed;
    display: -webkit-flex;
    display: flex;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    width: 35vw;
    height: 60vh;
    border-radius: .75vw;
    background-color: #3A4456;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 2vw;
    color: #3A4456;
}

.pendantBanner {
    display: float;
    background-color: #ffbb33;
    border-radius: .75vw .75vw 0vw 0vw;
    height: 14vh;
    width: 120%;
    font-family: "Roboto Condensed";
    font-size: 3vw;
    text-align: center;
    padding-top: 0.6vw;
}

.pendantText {
    padding: 1vw;
    font-family: "Roboto Condensed";
    font-size: 3vw;
    text-align: center;
    color: #DAE3EB;
}

.containerA {
    position: fixed;
    top: 14vw;
    bottom: 5vw;
    right: 10vw;
    font-family: "Open Sans", sans-serif;
    font-size: 1.75vw;
    color: #D2D6E8;
    background: #3A4456;
    cursor: pointer;
    border-radius: .75vw;
    border: 0vw;
    text-align: center;
    width: 35vw;
    height: 46.5vh;
}

.btnWide {
    font-family: "Open Sans", sans-serif;
    font-size: 3vw;
    color: #d2d6e8;
    font-weight: bold;
    cursor: pointer;
    border-radius: 1vw;
    padding-bottom: 1vw;
    border: none;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 35vw;
    background: b00b00;

    position: fixed;
    right: 12.5vw;
    z-index: 5;

    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}


/*************************/
/*       Html_Yoga       */
/*************************/

#yogaVideo {
    position: fixed;
    height: 100vh;
    right: 0vw;

}


/*************************/
/*     Html_HulaHoop     */
/*************************/

#hulaHoopVideo {
    position: fixed;
    width: 100vw;
    height: 100vh;
}


/**************************/
/* Html_BreathingExercise */
/**************************/

#breathingExerciseVideo {
    position: fixed;
    width: 100vw;
    height: 100vh;
}


/*************************/
/* Html_diabetesSmoking  */
/*************************/

#diabetesSmokingTab {
    position: fixed;
    display: -webkit-flex;
    display: flex;
    top: 3.5vw;
    bottom: 5vw;
    right: 10vw;
    width: 40vw;
    height: 85vh;
    border-radius: .75vw;
    background-color: #3A4456;
}

#diabetesSmokingBanner {
    position: fixed;
    width: 40vw;
    height: 7vw;
    border-radius: 0.75vw 0.75vw 0 0;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 3vw;
    color: #3A4456;
    padding-top: 1vw;
    background: #ffbb33;
}

#diabetesSmokingText {
    color: #DAE3EB;
    position: fixed;
    width: 35vw;
    font-family: "Open Sans", sans-serif;
    font-size: 1.5vw;
    color: #DAE3EB;
    padding: 2vw;
    padding-top: 9vw;
}

#smokingNumber {
    position: fixed;
    top: 43.5vw;
    left: 65.6vw;
    font-size: 1vw;
    font-family: OpenSans, Open Sans;
}


/****************************/
/* Html_heartDiseaseSmoking */
/****************************/

#heartDiseaseSmokingTab {
    position: fixed;
    display: -webkit-flex;
    display: flex;
    top: 3.5vw;
    bottom: 5vw;
    right: 10vw;
    width: 40vw;
    height: 85vh;
    border-radius: .75vw;
    background-color: #3A4456;
}

#heartDiseaseSmokingBanner {
    position: fixed;
    width: 40vw;
    height: 7vw;
    border-radius: 0.75vw 0.75vw 0 0;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 3vw;
    color: #3A4456;
    padding-top: 1vw;
    background: #ffbb33;
}

#heartDiseaseSmokingText {
    color: #DAE3EB;
    position: fixed;
    width: 35vw;
    font-family: "Open Sans", sans-serif;
    font-size: 1.5vw;
    color: #DAE3EB;
    padding: 2vw;
    padding-top: 9vw;
}


/*************************/
/*    Html_PollenCount   */
/*************************/

#pollenTab {
    position: fixed;
    top: 6vw;
    bottom: 5vw;
    right: 10vw;
    width: 35vw;
    height: 60vh;
    border-radius: .75vw;
    background-color: #3A4456;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 2vw;
    color: #DAE3EB;
}

#pollenBanner {
    background-color: #009F93;
    padding: 2vw 0vw 0vw 0vw;
    border-radius: .75vw .75vw 0vw 0vw;
    height: 14vh;
    font-family: "Roboto Condensed";
    font-size: 4vw;
    font-weight: bold;
    text-align: center;
}

#pollenText {
    color: #DAE3EB;
    margin-left: auto;
    margin-right: auto;
    font-family: "Open Sans", sans-serif;
    font-size: 2vw;
    color: #DAE3EB;
    padding: 0.5vw;
    text-align: center;
}

#pollen_city {
    font-size: 3vw;
    margin: 1vw;
}

#pollen_name {
    font-size: 2.5vw;
    margin-top: 5vw;
}

.pollen-slider {
    margin: 2vw 0 0 0;
    width: 100%;
}

.pollen-slider__range {
    -webkit-appearance: none;
    width: 25vw;
    height: 6vw;
    border-radius: 0.75vw;
    background: -webkit-gradient(linear, left top, right top, from(#00B64A), color-stop(50%, #ffbb33), to(#B4443E));
    background: -webkit-linear-gradient(left, #00B64A 0%, #ffbb33 50%, #B4443E 100%);
    background: linear-gradient(to right, #00B64A 0%, #ffbb33 50%, #B4443E 100%);
    outline: none;
    padding: 0;
    margin: 0;
    margin-top: -0.5vw;
    margin-bottom: 1.5vw;
}

.pollen-slider__range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.5vw;
    height: 7.5vw;
    border-radius: 0.5vw;
    background: #EEF0F6;
    cursor: arrow;
}

.pollen-slider__value {
    display: block;
    position: fixed;
    width: 5vw;
    color: #3A4456;
    line-height: 3vw;
    text-align: center;
    border-radius: 0.75vw;
    background: #D2D6E8;
    margin-left: 15%;
    margin-top: 1vw;
    font-family: "Roboto Condensed";
    font-size: 2.5vw;
    text-align: center;
    color: #111;
}

#pollenLowHigh {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-content: flex-start;
            align-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    margin: -5.25vw 1vw 9vw 1vw;
    font-size: 1vw;
}


/*************************/
/*    Html_AddisonTab    */
/*************************/

.addisonTab {
    position: fixed;
    top: 7vw;
    right: 10vw;
    width: 80vw;
    height: 70vh;
    border-radius: .75vw;
    margin-left: 45vw;
    background-color: rgba(58, 68, 86, 1);
}

#addisonBanner {
    padding: 2vw 0vw 0vw 10vw;
    background-color: #0098C6;
    border-radius: .75vw .75vw 0vw 0vw;
    height: 8vw;
    font-family: "Roboto Condensed";
    font-size: 4vw;
    font-weight: bold;
    text-align: center;
    color: #d2d6e8;
}

.btncloseaddison {
    float: right;
    font-family: "Open Sans", sans-serif;
    font-size: 2.5vw;
    color: #d2d6e8;
    font-weight: bold;
    background: #B4443E;
    cursor: pointer;
    border-radius: 0vw .75vw 0vw .5vw;
    padding-bottom: .5vw;
    border: 0vw;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 10.5vw;
    margin-top: -2vw;
}

#addisonBtnContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-content: flex-start;
            align-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    margin: 0 1vw 0 1vw;
}

#btnTakeMyMeds,
#btnNextMed {
    margin: 0vw;
    margin-top: 5vw;
}

#linebreak {
    width: 100%;
}

.icon, .text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center
  }

.begin-btn {
 font-family: "Open Sans", sans-serif;
 font-size: 4vw;
 color: #d2d6e8;
 font-weight:bold;
 background: #016E75;
 cursor: pointer;
 border-radius: 1vw;
 padding-bottom: 1vw;
 border: 0vw;
    -webkit-align-items: center;
            align-items: center;
 text-align: center;
 width: 20vw; /*golden ratio lel*/
 height: 14vh;
 top: 40vh;
 left: 40vw;
 position: absolute;
 }

.begin-btn:active,

.begin-btn:focus {
 -webkit-animation: borderPulseDone 1000ms infinite ease-out;
         animation: borderPulseDone 1000ms infinite ease-out;
 -webkit-animation: borderPulseDone 1s;
         animation: borderPulseDone 1s;
 background-color: #49C0AD;
 -webkit-transition: background 1s;
 transition: background 1s;
 outline: 0em;
   color: #ffffff;
}

/* Declare border pulse animation */
@keyframes borderPulseDone {
/* inside pulse */
  0% {
    box-shadow: inset 0em 0em 0em .15em rgb(73, 192, 173, .4), 0em 0em 0em 0em rgb(73, 192, 173, 1);
  }
/* outside pulse */
  100% {
    box-shadow: inset 0em 0em 0em 0em rgb(73, 192, 173, .2), 0em 0em 0em .4em rgb(73, 192, 173, 0);
  }
}

/* Initiate Auto-Pulse animations */
button.pulse-button {
 -webkit-animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
         animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
  background-position: center;
  -webkit-transition: background .8s;
  transition: background .8s;
}

/* Gradient properties */
button.btndone {
  background-position: center;
  -webkit-transition: background .8s;
  transition: background .8s;
}


/*************************/
/*  Html_vitalsTutorials */
/*************************/

#vitalsTutorialImages {
    position: fixed;
    top: 0vw;
    bottom: 5vw;
    right: 10vw;
    width: 35vw;

}


/****************************/
/* HTML_CalibrationInstruct */
/****************************/

.calibrationBackground {
    height: 1100px;
    width: 2200px;
    background-color: #3A4456;
}

.calibrationInstructions {
    padding-top: 20vh;
    font-family: "Open Sans", sans-serif;
    font-size: 50px;
    color: #EEF0F6;
    cursor: pointer;
    text-align: center;
}

.calibrationSteps {
    font-family: "Open Sans", sans-serif;
    font-size: 30;
    color: #EEF0F6;
    text-align: center;
}

.calibrationCam {
    font-family: "Open Sans", sans-serif;
    font-size: 30;
    color: #8CDDF7;
    text-align: center;
}

.calibrationMic {
    font-family: "Open Sans", sans-serif;
    font-size: 30;
    color: #D2B2DD;
    text-align: center;
}

.calibrationSpeak {
    font-family: "Open Sans", sans-serif;
    font-size: 30;
    color: #49C0AD;
    text-align: center;
}

.calibrationSpeedTest {
    font-family: "Open Sans", sans-serif;
    font-size: 30;
    color: #fff;
    text-align: center;
}

.calibrationClose {
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    color: #EEF0F6;
    text-align: center;

    margin-left: 1000px;
    background: #B4443E;
    border-radius: 10px;
    border: none;
    width: 200px;
    height: 75px;
    overflow: hidden;
}

.instructionsBtn {
    position: fixed;
    top: 5vw;
    left: 5vw;
    font-family: "Open Sans", sans-serif;
    font-size: 3em;
    color: #ffffff;
    background: #00C851;
    cursor: pointer;
    border-radius: .4em;
    padding-bottom: 0.2em;
    border: 0em;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
}


/*************************/
/*    Calibration_Cam    */
/*************************/

.camera {
    position: fixed;
    top: 10vw;
    left: 57vw;
    width: 30vw;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 5em;
    color: #d2d6e8;
    background: #0b2545;
    cursor: pointer;
    border-radius: 0.75vw;
    padding-bottom: 0.2em;
    border: 0em;
}

/* What happens on mouse-down */
.camera:active,

/* What happens once selected */
.camera:focus {
    -webkit-animation: borderPulseCamera 1000ms infinite ease-out;
            animation: borderPulseCamera 1000ms infinite ease-out;
    -webkit-animation: borderPulseCamera 1s;
            animation: borderPulseCamera 1s;
    background-color: #045c84;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

/* Declare border pulse animation */
@-webkit-keyframes borderPulseCamera {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(4, 92, 132, .4), 0em 0em 0em 0em rgba(4, 92, 132, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(4, 92, 132, .2), 0em 0em 0em .4em rgba(4, 92, 132, 0);
    }
}
@keyframes borderPulseCamera {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(4, 92, 132, .4), 0em 0em 0em 0em rgba(4, 92, 132, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(4, 92, 132, .2), 0em 0em 0em .4em rgba(4, 92, 132, 0);
    }
}

/* Initiate Auto-Pulse animations */
button.pulse-button {
    -webkit-animation: borderPulse1 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
            animation: borderPulse1 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}

/* Gradient properties */
button.speakers {
    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}


/*************************/
/*    Calibration_Mic    */
/*************************/

.microphone {
    position: fixed;
    top: 20vw;
    left: 57vw;
    width: 30vw;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 5em;
    color: #d2d6e8;
    background: #441155;
    cursor: pointer;
    border-radius: 0.75vw;
    padding-bottom: 0.2em;
    border: 0em;
}

/* What happens on mouse-down */
.microphone:active,

/* What happens once selected */
.microphone:focus {
    -webkit-animation: borderPulseMic 1000ms infinite ease-out;
            animation: borderPulseMic 1000ms infinite ease-out;
    -webkit-animation: borderPulseMic 1s;
            animation: borderPulseMic 1s;
    background-color: #bb84d0;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}


/* --------------- Animation --------------- */

/* Declare border pulse animation */
@-webkit-keyframes borderPulseMic {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(187, 132, 208, .4), 0em 0em 0em 0em rgba(187, 132, 208, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(187, 132, 208, .2), 0em 0em 0em .4em rgba(187, 132, 208, 0);
    }
}
@keyframes borderPulseMic {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(187, 132, 208, .4), 0em 0em 0em 0em rgba(187, 132, 208, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(187, 132, 208, .2), 0em 0em 0em .4em rgba(187, 132, 208, 0);
    }
}

/* Initiate Auto-Pulse animations */
button.pulse-button {
    -webkit-animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
            animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}

/* Gradient properties */
button.microphone {
    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}


/*************************/
/*  Calibration_Speakers */
/*************************/

.speakers {
    position: fixed;
    top: 30vw;
    left: 57vw;
    width: 30vw;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 5em;
    color: #d2d6e8;
    background: #03312e;
    cursor: pointer;
    border-radius: 0.75vw;
    padding-bottom: 0.2em;
    border: 0em;
}

/* What happens on mouse-down */
.speakers:active,

/* What happens once selected */
.speakers:focus {
    -webkit-animation: borderPulseSpeakers 1000ms infinite ease-out;
            animation: borderPulseSpeakers 1000ms infinite ease-out;
    -webkit-animation: borderPulseSpeakers 1s;
            animation: borderPulseSpeakers 1s;
    background-color: #49c0ad;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

/* Declare border pulse animation */
@-webkit-keyframes borderPulseSpeakers {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(73, 192, 173, .4), 0em 0em 0em 0em rgba(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(73, 192, 173, .2), 0em 0em 0em .4em rgba(73, 192, 173, 0);
    }
}
@keyframes borderPulseSpeakers {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(73, 192, 173, .4), 0em 0em 0em 0em rgba(73, 192, 173, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(73, 192, 173, .2), 0em 0em 0em .4em rgba(73, 192, 173, 0);
    }
}

/* Initiate Auto-Pulse animations */
button.pulse-button {
    -webkit-animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
            animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}

/* Gradient properties */
button.speakers {
    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}


/*************************/
/*   Calibration_Speed   */
/*************************/

.speed {
    position: fixed;
    top: 40vw;
    left: 57vw;
    width: 30vw;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 5em;
    color: #d2d6e8;
    background: #3a4456;
    cursor: pointer;
    border-radius: 0.75vw;
    padding-bottom: 0.2em;
    border: 0em;
}


/* What happens on mouse-down */
.speed:active,

/* What happens once selected */
.speed:focus {
    -webkit-animation: borderPulseSpeed 1000ms infinite ease-out;
            animation: borderPulseSpeed 1000ms infinite ease-out;
    -webkit-animation: borderPulseSpeed 1s;
            animation: borderPulseSpeed 1s;
    background-color: #758299;
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: 0em;
    color: #ffffff;
}

/* Declare border pulse animation */
@-webkit-keyframes borderPulseSpeed {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(117, 130, 153, .4), 0em 0em 0em 0em rgba(117, 130, 153, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(117, 130, 153, .2), 0em 0em 0em .4em rgba(117, 130, 153, 0);
    }
}
@keyframes borderPulseSpeed {

    /* inside pulse */
    0% {
        box-shadow: inset 0em 0em 0em .15em rgba(117, 130, 153, .4), 0em 0em 0em 0em rgba(117, 130, 153, 1);
    }

    /* outside pulse */
    100% {
        box-shadow: inset 0em 0em 0em 0em rgba(117, 130, 153, .2), 0em 0em 0em .4em rgba(117, 130, 153, 0);
    }
}

/* Initiate Auto-Pulse animations */
button.pulse-button {
    -webkit-animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
            animation: borderPulse 1000ms infinite ease-out, colorShift 10000ms infinite ease-in;
    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}

/* Gradient properties */
button.speed {
    background-position: center;
    -webkit-transition: background .8s;
    transition: background .8s;
}


/*************************/
/* Calibration_SpeedTest */
/*************************/

.corner-wrapper {
    position: fixed;
    top: 40vw;
    left: 57vw;
    width: 28.4vw;
    display: block;
    overflow: hidden;
    height: 180px;
    border-radius: 0.75vw;
    -webkit-transform: translateZ(10px);
            transform: translateZ(10px);
    border: 15px solid #fff;
}

#speedTestIframe {
    width: 30vw;
    height: 15vw;
    border: none;
    frameborder: none;
    position: relative;
    left: -35%;
    top: -45%;
}

/*****************************/
/* Calibration_HideSpeedTest */
/*****************************/

.HideSpeedTest {
    position: fixed;
    top: 40vw;
    left: 88vw;
    font-family: "Open Sans", sans-serif;
    font-size: 3em;
    color: #ffffff;
    background: #cc0303;
    cursor: pointer;
    border-radius: .4em;
    padding-bottom: 0.2em;
    border: 0em;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
}


/*************************/
/*   IFRAME_SpeedTest    */
/*************************/


.corner-wrapper {
    display: block;
    overflow: hidden;
    width: 475px;
    height: 180px;
    border-radius: 10px;
    -webkit-transform: translateZ(10px);
            transform: translateZ(10px);
    border: 15px solid #fff;
    z-index: 25;



}

#ifrSpeedTest {
    width: 787px;
    height: 400px;
    border: none;
    frameborder: none;


    position: relative;
    left: -35%;
    top: -45%;
}

/*************************/
/*    Html_HelpScreen    */
/*************************/

.helpScreenBackground {
    position: fixed;
    width: 100vw;
    height: 100vh;

    background-image: url("https://s3.amazonaws.com/addison-project-anim-files/UI+Images/Vitals+Tutorial+Images/TabletUI_BLANK_13-5x9AR_v01_01.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
}

.helpScreenTitle {
    padding: 2vw 0vw 2vw 0vw;
    background-color: #009F93;
    height: 19vh;
    font-family: "Roboto Condensed";
    font-size: 8vw;
    font-weight: bold;
    text-align: center;
    color: #EEF0F6;
    box-shadow: 0vw 0vw 3vw #009F93;
}

.helpScreenText {
    padding: 2vw;
    font-family: "Roboto Condensed";
    font-size: 5vw;
    text-align: center;
    color: #DAE3EB;
}

/*************************/
/*    Html_CSAudioVis    */
/*************************/

.CSAudVis {
    font-family: sans-serif;
    text-align: center;
    color: #009F93;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.CSAudVis>div {
    display: inline-block;
    width: 0.75vw;
    height: 15vw;
    margin: 2vw 2vw 2vw;
    border-radius: 0.75vw;
    background: currentColor;
    -webkit-transform: scaleY(0.5);
            transform: scaleY(0.5);
    opacity: .25;
    box-shadow: 0vw 0vw 4vw #009F93;
}

.CSAudVis.error {
    color: #f7451d;
    min-width: 20em;
    max-width: 30em;
    margin: 0 auto;
    white-space: pre-line;
}

/*************************/
/*   Html_HelpAudioVis   */
/*************************/

.HelpAudVis {
    font-family: sans-serif;
    text-align: center;
    color: #009F93;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.HelpAudVis>div {
    display: inline-block;
    width: 0.75vw;
    height: 15vw;
    margin: 2vw 2vw 2vw;
    border-radius: 0.75vw;
    background: currentColor;
    -webkit-transform: scaleY(0.5);
            transform: scaleY(0.5);
    opacity: .25;
    box-shadow: 0vw 0vw 4vw #009F93;
}

.HelpAudVis.error {
    color: #f7451d;
    min-width: 20em;
    max-width: 30em;
    margin: 0 auto;
    white-space: pre-line;
}

/*************************/
/* Html_PendantAudioVis  */
/*************************/

.PendantAudVis {
    font-family: sans-serif;
    text-align: center;
    color: #009F93;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.PendantAudVis>div {
    display: inline-block;
    width: 0.75vw;
    height: 15vw;
    margin: 2vw 2vw 2vw;
    border-radius: 0.75vw;
    background: currentColor;
    -webkit-transform: scaleY(0.5);
            transform: scaleY(0.5);
    opacity: .25;
    box-shadow: 0vw 0vw 4vw #009F93;
}

.PendantAudVis.error {
    color: #f7451d;
    min-width: 20em;
    max-width: 30em;
    margin: 0 auto;
    white-space: pre-line;
}

#nutritionPlan-header-text {
 font-size: 6vh;
 padding-top: .5vw;
}

.long-header-text {
 font-size: 4.5vh;
 padding-top: 1vw;
}


/*************************/
/*      Html_MicDot      */
/*************************/

#stuff:focus {outline:0;}
.micDotON {
  position: absolute;
  top: 1vw;
  left: 95vw;
  background-color: #00B64A;
  border: none;
  width: 4vw;
  height: 4vw;
  border-radius: 50vw;
}
.micDotOff {
  top: 1vw;
  left: 95vw;
  position: absolute;
  background-color: #B4443E;
  border: none;
  width: 3vw;
  height: 3vw;
  border-radius: 50vw;
}







/*************************/
/*      CFRSI Gait       */
/*************************/




/* ############# Pagination Style  ############ */
YN
.highlight-question {
    background-color: white;
}


.container{
    display: block;
    position: absolute;
    width: 900px; 
    height: 800px;
    top: 30px;
    bottom: 25px;
    left: 57em;
    border: 6px solid;
    border-color: #30476C;
    border-radius: 20px;
    background-color: rgba(179,197,228,0.6);
    /*padding: 2.5em 2em;*/
    margin: 0;
}


label {
    width:78%;
    height:40%;
    display: inline-block;
    font-size:28px;
}


.YN {
    float: right;
    margin-left:10px;
    margin-top: 60px;
    height: 100px;
    width: 130px;
    border-radius:23px;
    border-style:solid;
    border-color:#070d18;
    margin-bottom:0;
    font-size:28px;
    color:white;
    background-color:#30476C;
    text-align: center;
}

.UD {
    float: right;
    margin-left:10px;
    height: 75px;
    width: 130px;
    border-radius:23px;
    border-style:solid;
    border-color:#070d18;
    margin-bottom:0;
    font-size:26px;
    color:white;
    background-color:#30476C;
    text-align: center;
}


.userInput {
    float:right;
    margin-left:25px;
    height: 85px;
    width: 270px;
    border-radius:23px;
    border-style:solid;
    border-color:#070d18;
    margin: 10px 0px;
    font-size:28px;
    color:white;
    background-color:#30476C;
    text-indent: 15px;
}


.pagination-items{
    width: 91%;
    height: 97%;
    margin:auto;
    padding: auto;
    padding-top: 5vh;
}


.pagination {
    position: static;
    width: 50%;
    float: right;
    margin-top: 70px;
    /*margin-right: auto;
    margin-bottom: 10em;
    margin-left: 20em;*/
    text-align: center;
}


.pagination:after {
    position:static;
    /*content: ''; */
    clear: both;
}


.pagination-button {
    visibility: hidden;
    position:static;
    font-size:56px;
    display: inline-block;
    padding: 0px 40px;
    background-color: white;
    border: 2px solid #30476C;
    border-radius: 43px;
    color: #30476C;
    cursor: pointer;
    -webkit-transition: background 0.1s, color 0.1s;
    transition: background 0.1s, color 0.1s;
}

.pagination-button.page-num {
    visibility: hidden;
    position:static;
    font-size: 6px;
    display: inline-block;
    padding: 2px 0px;
    background-color: white;
    border: 2px solid #30476C;
    border-radius: 43px;
    color: #30476C;
    cursor: pointer;
    -webkit-transition: background 0.1s, color 0.1s;
    transition: background 0.1s, color 0.1s;
}



.pagination-button:hover {
    position:static;
    background-color: #ddd;
    color: #36c;
}


.pagination-button.active {
    position:static;
    background-color: #bbb;
    border-color: #bbb;
    color: #36c;
}


.pagination-button:first-of-type {
    position:static;
    visibility: visible;
}


.pagination-button:last-of-type {
    position:static;
    visibility: visible;
}


/* arbitrary styles */
.heading {
    text-align: center;
    max-width: 500px;
    //margin: 20px auto;
}


.article-loop {
    border-radius: 20px;
    display: inline-block;
    width: 95%;
    padding: 14px 9px;
    margin: 10px 5px;
    font-size: 40px;
    border: 6px solid #30476C;
    background-color: #B3C5E4;
}

.article-loop h1{
    float: left;
    width: 60%;
    font-weight: bold;
    font-size: 30px;
}

.article-loop label{
    float: left;
    width: 75%;
    padding: 10px;
    font-weight: bold;
    font-size: 20px;
}

.pages {
    visibility: hidden;
    width:9px;
    padding:0px;
}


.invisible-article {
    width: 0px;
    height: 0px;
    /*visibility: hidden;*/
    display: none;
}

/* ######### AddisonResponse ######## */


.addisonText h1{
    width: 60em;
    height: 4.5em;
    margin: auto;

    background: -webkit-linear-gradient(top, rgba(0,0,0,0), rgba(0,0,0,1));

    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
}

.addisonText p {
    width: 60em;
    margin-top: 33em;
    margin-left: 3em;
    padding-top: 0.5em;
    line-height: 1.3em;

    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9em;
    font-weight: 400;
    text-align: center;
}

/* ########## UserResponse ######### */


#addisonResponse textarea {
    align: center;
    margin: 5px;
    position: center;
    text-align: left;
    border-radius: 1em;
}


#userText {
    width:220px;
    height: 75px;
    border-radius: 10px;
    border: 3px solid;
    border-color:#30476C;
    font-size: 28px;
}


.submit-button {
    width: 30%;
    border: 2px solid;
    height: 30%;
    background: white;
    border-radius: 38px;
    border-color: #30476C;
    font-weight: bold;
    font-size: 28px;
    color: #30476C;
    padding: 15px 0px 15px 0px;
    font-family: Roboto;
}

.submit-button:hover {
    color:white;
    background: var(--button-color);
}


/* ########## Agreement ########## */

* {
    font-family: 'Roboto', sans-serif;
    --button-color: #30476C;
    --circle-color: #bbbbbb;
}


.agreementDiv{
    position: absolute;
    width: 800px;
    height: 780px;
    overflow: auto;
    border-radius: 23px;
    border-style: solid;
    border-color: #070d18;
    background-color: #B3C5E4;
    padding: 1% 2%;
    font-family: 'Roboto';
    font-size: 26px;
    left: 40em;
    top: 1em;
}

.termsbtn {
    float: right;
    margin-left:10px;
    height: 75px;
    width: 130px;
    border-radius:32px;
    border-style:solid;
    border-color:#070d18;
    margin-bottom:0;
    font-size:26px;
    color:white;
    background-color:#30476C;
    text-align: center;
}

.termsbtn:active {
    color: var(--button-color);
    background-color:#fff;
    letter-spacing: 5px;
}

::-webkit-scrollbar {
    width: 20px !important;
}

::-webkit-scrollbar:vertical {
    width: 20px !important;
}

::-webkit-scrollbar:horizontal {
    width: 20px !important;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    background: #0B2545 !important;
}

::-webkit-scrollbar-track {
    border-radius: 10px !important;
    background: #DAE3EB !important;
}


/* ##########  YesNoButtons ######### */

* {
    font-family: 'Roboto', sans-serif;
    --button-color: #30476C;
    --circle-color: #bbbbbb;
}


.is-paused {
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
}


.spin-button {
    position: static;
    width: 200px;
    height:70px;
    margin: 0px 0px 0px 20px;
    outline: none;
    text-align: center;
    border-radius: 100px;
    background: #fff;
    border: .25vw solid;
    color: var(--button-color);
    letter-spacing: 1px;
    text-shadow:5;
    font-weight: bold;
    font-size: 28px;
    cursor: pointer;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    font-family: 'Roboto';
}


.spin-button:after {
    /*content:"";  */
}


.spin-button:hover{
    color:white;
    background: var(--button-color);
}


.spin-button:active{
    letter-spacing: 5px ;
}


.onclic {
    position: static;
    /*margin: auto;*/
    width: 70px;
    height: 70px;
    border-color: var(--circle-color);
    border-width:5px;
    font-size:0;
    border-left-color:var(--button-color);
    -webkit-animation: rotating 1.0s .25s linear infinite;
            animation: rotating 1.0s .25s linear infinite;
}


.onclic:after {
    content:"";
}


.onclic:hover {
    color: var(--button-color);
    background: white;
}


.validate {
    font-size:28px;
    color: white;
    background: var(--button-color);

}


.validate:after {
    font-family:'FontAwesome';
    /* content:"\f00c"; */
}


@-webkit-keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}


@keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}

/*
#yes-button{
    visibility:visible;
}


#no-button{
    visibility:visible;
}
*/


.YN-buttons {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    top: 8vh;
} 


/* ##########  Begin Button ######### */




.moveBegin {
    left: 33em;
    top: 21em;

} 

 
.begin {
    top: 36em;
    left: 26em;
    width: 1000px;
    height: 590px;


}

.select-button {
line-height: 20px;
    font-weight: bold;
    font-size: 26px;
    color: #30476C;
    border: .25vw solid;
    color: #30476C;
    background-color: white;
    border-color: #30476C;
    margin-left: 26px;
    margin-top: 26px;
    margin-bottom: 15px;
    border-radius: 40px;
    width: 140px;
    height: 75px;
}


#beginButton{
    border: .25vw solid;
    font-size:70px;

}

/* ##########  Help Button ######### */



.helpDiv{
    margin-top:100px;
    margin-bottom:100px;
    margin-left:50px;
    width:100px;
    height:100px;
    border-radius:50px;
  outline-style: none;
}

.selectors{
    margin-top:600px;
    margin-bottom:100px;
    margin-left:1200px;
    width:100px;
    height:100px;
    border-radius:50px;
  outline-style: none;
}

.question{
  
    padding-left: 0px;
    padding-right: 0px;
    padding-top:2px;
    color: white;
    background-color: #30476C;
    border-radius: 65px;
    border-color: #30476C;
    position: relative;
    height: 75px;
    width: 75px;
    margin: 25px;
    border: none;
   
    }

 .question:active{

  color: #30476C;
  background-color: white;
  border-color:white;
   
}

  
.question:after {
  outline-style: none;
    font-family:'FontAwesome';
    /* content:"\f059"; */
    font-size: 72px;
}


.restartButton{
    line-height: 20px;
    font-weight: bold;
    font-size: 26px;
    color: #30476C;
    border: .25vw solid;
    color: #30476C;
    background-color: white;
    border-color: #30476C;
    margin-left: 26px;
    margin-top: 26px;
    margin-bottom: 15px;
    border-radius: 40px;
    width: 140px;
    height: 75px;
}



.restartButton:active{
    color:white;
    background-color: #30476C;
}



/*.appAbout-my-firstInstructions {
    font-family: "Open Sans", sans-serif;
    font-size: 1.5vw;
    color: #d2d6e8;
    font-weight: bold;
    cursor: pointer;
    border-radius: .75vw;
    padding: 1vw;
    border: 0vw;
    align-items: center;
    text-align: center;
    width: 30vw;
    background: #0B2545;
    margin: 0vw;
    position: absolute;
    top: 30vh;
    left: 60vw;
}*/


#saveBtn {}
