@import '../styles';

.recent-balls {
  @include fade-in--transform(1s, .5s, 0%, -200%);

  background-color: $medium-dark-blue;
  border: solid;
  border-color: $dark-dark-blue;
  border-radius: 15px;
  border-width: .5vw;
  display: flex;
  justify-content: flex-end;
  left: 50vw; 
  margin: 1vw 0 0 0;
  opacity: 0;
  top: 0vw;
  position: absolute;
  width: 45vw;
}

// .recent_balls__ball {
//   @include ball(7vw);
//   box-shadow: 2px 10px 18px #333;
// }

.recent-balls__ball-number {
  font-size: 3vw;
  margin: 0 0 0 0;
}

.recent-balls__ball-letter {
  font-size: 1.5vw;
  margin: -.5vw 0 -.8vw 0;
}

.recent-balls__label {
  color: $dark-dark-blue;
  font-size: 3vw;
  font-weight: bold;
  margin: 0;
  padding-bottom: 1vw;
}

.recent-balls__ball {
  @include ball(7vw);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 10px 18px #333;
  overflow: hidden;
}

.recent-balls__ball-inner1 {
  @include ball(6vw);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: white;
}

.recent-balls__ball-inner2 {
  @include ball(5.5vw);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.recent-balls__ball-inner3 {
  @include ball(5vw);
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
}

/* ball placeholder for empty slot in recent-balls */
.recent-balls__empty-spot {
  background-color: darken($medium-dark-blue, 6);
  flex: 1;
  width: 7vw;
  height:7vw;
  margin: 1vw;
  border-radius: 50%;
}