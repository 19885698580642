div, input {
	--board-width: 42vw;
	--board-height: 63vh;
}

.sudoku-board {
	position: fixed;
	display: flex;
	flex-direction: column;
	left: 50vw;
	top: 30vh;
	width: var(--board-width);
	height: var(--board-height);
	border: 0;
	z-index: 1000000000;
	background: black;
}

.sudoku-row {
	display: flex;
	flex-direction: row;
	width: calc(var(--board-width) - 1);
	height: calc(var(--board-height) / 9);
	vertical-align: middle;
}

.sudoku-cell {
	display: block;
	position: relative;
	align-content: center;
	justify-content: center;
  	font-family: "Open Sans", sans-serif;
	width: calc(var(--board-width) / 10.5);
	height: calc(var(--board-height) / 11.5);
	padding:auto;
	margin: 0.5vw 0.5vh;
	border: 0;
	font-size: 1.5vw;
	text-align: center;
	vertical-align: middle;
	border: 1px solid;
	background: white;
}
