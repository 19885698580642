
#vitalCont {

	position: fixed;
	top: 0%;
	right: 0%;
	width: 35vw;
	height: 30vw;

    background-image: linear-gradient(to bottom, #064077, #0A69C4);
	padding: 2%;
	border-radius: 0% 0% 0% 5%;
  
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
	
	font-family: 'Open Sans', sans-serif;
	color: #fff;
  }

.vitalName {
	font-size: 5vw;
	text-align: center;
}

.vitalNumber{
	font-size: 8vw;
	margin-bottom: -10%;
	margin-top: -10%;
}