@import '../../styles/modules/base';

.app-bingo__container {
  position: absolute;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

@mixin ball($size) {
  width: $size;
  height: $size;
  margin: 1vw;
  text-align: center;
  font-size: 2vw;
  line-height: 3vw;
  font-weight: 700;
  border-radius: 50%;
}



