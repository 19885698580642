@import '../styles';

.bingo-boredo {
  @include fade-in--transform(1s, 0s, 200%, 0%);

  padding: 0;
  display: flex; 
  flex-direction: column;
  position: absolute; 
  left: 50vw; 
  opacity: 0;
  top: 12vw; 
  width: 46vw;
  z-index: 10000;
}

.bingo-table {
  width: 100%;
}

.bingo-table__container {
  border-radius: 15px;
  left: 45vw; 
  overflow: hidden;
  padding: 0 0 0 0;
  top: 20vh;
  width: 100%;
}

.bingo-table__header {
  font-size: 4vw;
  padding: 1%;
  width: 20%;
}

.bingo-table__header--1 {
  background-color: #E53935;
}

.bingo-table__header--2 {
  background-color: #8E24AA;
}

.bingo-table__header--3 {
  background-color: #2196F3;
}

.bingo-table__header--4 {
  background-color: #4CAF50;
}

.bingo-table__header--5 {
  background-color: #FF9800;
}

.cell {
  background-color: white !important;
  border: 3px solid #eee;
  border-bottom: 0;
  font-weight: 700;
  font-size: 3vw;
  height: 6vw !important;
  padding: 0;
  width: 20% !important;
  line-height: 50px;
  text-align: center;
}

.cell-label {
  margin: 0;
  padding: 0;
}

.not-selected {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.selected {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  overflow: visible; 
  height: 50px;
}

.token {
  align-items: center;
  background-color: #9944BB;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 5.5vw;
  width: 5.5vw;
}

/* table header */
th {
  color: white !important;
  height: 4vw;
  width: 4vw;
}

/* table cell */
td {
  height: 4vw !important;
  width: 4vw !important;
}

table {
  table-layout: fixed;
}