@import '../../styles/modules/base';

@mixin button($size) {
    position: fixed;
	top: $size;
	left: 70vw;
    width: 20vw;
	text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 4em;
    color: #fff;
    background-image: linear-gradient(to bottom, #064077, #0A69C4);
    cursor: pointer;
    border-radius: 0.75vw;
    padding-bottom: 0.2em;  
    border: 0.35vw solid #00AC28;
    box-shadow: 0px 0px 15px #00AC28;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @mixin checkbox($size) {
    position: fixed;
	top: $size;
	left: 91vw;
    width: 3vw;
	text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 3em;
    color: #fff;
    background-image: linear-gradient(to bottom, #064077, #0A69C4);
    cursor: pointer;
    border-radius: 0.75vw;
    padding-bottom: 0.2em;  
    border: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .instruction {
    font-Size: 1.5vw;
    color: #fff;
    font-Weight: bold;
    border-Radius: .75vw;
    padding: 1vw;
    border: 0vw;
    align-Items: center;
    text-Align: center;
    width: 20vw;
    background-image: linear-gradient(to bottom, #064077, #0A69C4);
    margin: 0vw;
    position: absolute;
    top: 30vw;
    left: 45vw;
    height: 15vw;
    border: 0.35vw solid #00AC28;
    box-shadow: 0px 0px 15px #00AC28;
  }
